import React, { Component } from "react";
import { FaRegHandPointDown } from "react-icons/fa";
class ApplicationInstruction extends Component {
  render() {
    return (
      <>
        {/* {this.props.status === "" ? ( */}
        <>
          <p>
            <b>Instructions</b>
          </p>
          <hr />

          <ul>
            To submit programs of interest, Please select
            <li>
              1. Select the campus and program of your interest and then click
              add button
            </li>
            <li>2. Your are required to add a maximum of five programs</li>
            <li style={{ paddingTop: "5px" }}>
              <b>Note</b>. The fields with * are mandatory
            </li>
          </ul>
        </>
        {/* ) : ( */}
        <>
          {/* <p>
              <b>Instructions</b>
            </p>
            <hr />

            <ul>
              Please fill your Advanced Secondary Education through answering
              different questions which will be prompted to you{" "}
              <li>
                If you have not attended any Advanced Secondary school,please
                select No and confirm
              </li>
              <li style={{ paddingTop: "5px" }}>
                <b>Note</b>. The fields with * are mandatory
              </li>
              <br></br>
              <li>
                The form can found below <FaRegHandPointDown size={25} />
              </li>
              <br></br>
            </ul>
            {console.log(parseInt(localStorage.getItem("stageid")))} */}
        </>
        {/* )} */}
      </>
    );
  }
}
export default ApplicationInstruction;
