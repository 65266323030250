import React, { Component } from "react";
class GuestFooter extends Component {
  getCurrentYear = () => {
    return new Date().getFullYear();
  };
  render() {
    return (
      <div>
        <div className="footer-wrapper">
          <div className="copy-rights">
            <div className="row">
              <div className="col-md-3">&nbsp;</div>
              <div className="col-md-6 footer-content">
                <p>
                  {" "}
                  Copyright {this.getCurrentYear()} © Dar es Salaam Maritime
                  Institute . All Rights Reserved
                </p>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GuestFooter;
