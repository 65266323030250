import React, { Component } from "react";
import { Container } from "reactstrap";
import ReactLoading from "react-loading";
import ReferenceDisplay from "./referenceDisplay";
import ReferenceFillingInstruction from "./referenceFillingInstruction";
import AddReference from "./addReference";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import Alert from "@mui/material/Alert";
import { getReferenceDetails } from "./services";
class CollegeReference extends Component {
  state = {
    answer_id: null,
    filling_arrow: "right",
    reference_data: [],
    stage: parseInt(localStorage.getItem("stageid")),
    info_loading: null,
    network_error: false,
  };
  componentDidMount() {
    this.getCollegeList();
  }

  getCollegeList = async () => {
    this.setState({ info_loading: true });

    let payload = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };
    const res = await getReferenceDetails(payload);
    // console.log(res.data.data[0].attachmentPath);
    if (res.data.status === 200) {
      this.setState({
        reference_data: res.data.data,
        reference: res.data.data[0].attachmentPath,
        info_loading: false,
        network_error: false,
        filling_arrow: "right",
      });
    } else {
      this.setState({
        reference_data: [],
        info_loading: false,
        network_error: false,
        filling_arrow: "right",
      });
    }
  };

  render() {
    console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  Study Permit and Passport
                </div>
                <div className="float-right card-header-title">
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-body">
                <div className="block">
                  {this.state.info_loading === true ? (
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-2">
                        <ReactLoading type="cylon" color="#000" />
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  ) : (
                    <>
                      {this.state.network_error === true ? (
                        <>
                          <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                              <div className="alert alert-warning">
                                <strong>Warning!</strong> You are facing a
                                connection problem, please contact the system
                                administrator OR try later
                              </div>
                            </div>
                            <div className="col-sm-1"></div>
                          </div>
                        </>
                      ) : (
                        <>
                          {this.state.reference_data.length > 0 ? (
                            <>
                              {this.state.stage === 2 ? (
                                <>
                                  <div className="row">
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-4">
                                      <ReferenceFillingInstruction
                                        status={this.state.filling_arrow}
                                      />
                                      <AddReference
                                        reference_data={
                                          this.state.reference_data
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-7">
                                      <ReferenceDisplay
                                        reference_data={
                                          this.state.reference_data
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {this.state.stage > 2 ? (
                                    <>
                                      <div className="row">
                                        <div className="col-sm-1"></div>
                                        <div className="col-sm-10">
                                          <ReferenceDisplay
                                            reference_data={
                                              this.state.reference_data
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-1"></div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <>
                                        <div className="row">
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-10">
                                            <Alert severity="warning">
                                              Please fill the previous stages
                                              Information
                                            </Alert>
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>
                                      </>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {this.state.stage < 2 ? (
                                <>
                                  {" "}
                                  <Alert severity="warning">
                                    <strong>Warning!</strong> Fill previous
                                    details.
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  {this.state.stage === 2 ? (
                                    <div className="row">
                                      <div className="col-sm-1">
                                        {/*Display steps*/}
                                      </div>
                                      <div className="col-sm-4">
                                        <ReferenceFillingInstruction
                                          status={this.state.filling_arrow}
                                        />
                                      </div>
                                      <div
                                        className="col-sm-7"
                                        style={{ paddingTop: "55px" }}
                                      >
                                        <AddReference
                                          reference_data={
                                            this.state.reference_data
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      <Alert severity="info">
                                        <strong>Information!</strong> You do not
                                        have Study Permit and Passport
                                        information
                                      </Alert>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default CollegeReference;
