import React, { Component } from "react";
import { FaRegHandPointDown } from "react-icons/fa";
class SecondaryInstruction extends Component {
  render() {
    return (
      <>
        <ul>
          Please fill your Secondary education through answering different
          questions which will be prompted to you.
          <br></br>
          <li>If you have not attended any Secondary</li>
          education select no.
          <li style={{ paddingTop: "1px" }}>
            <b>Note</b>. The fields with * are mandatory
          </li>
          <li style={{ paddingTop: "1px" }}>
            The form can found below <FaRegHandPointDown size={25} />
          </li>
        </ul>
      </>
    );
  }
}
export default SecondaryInstruction;
