export default function localStorageSetup(data) {
  //localStorage.setItem("token", JSON.stringify(data.token));
  localStorage.setItem("token", JSON.stringify(data.details.tokenValue));
  localStorage.setItem(
    "authUser",
    JSON.stringify(data.userAuthentication.principal.applicant)
  );
  localStorage.setItem(
    "id",
    data.userAuthentication.principal.applicant.applicationProfile.id
  );
  localStorage.setItem(
    "applicationcode",
    data.userAuthentication.principal.applicant.id
  );
  localStorage.setItem(
    "awardId",
    data.userAuthentication.principal.applicant.applicationProfile.award.id
  );
  localStorage.setItem(
    "academicYear",
    data.userAuthentication.principal.applicant.applicationProfile.academicYear
  );
  localStorage.setItem(
    "stageid",
    data.userAuthentication.principal.applicant.applicationProfile
      .applicationStage.stageid
  );
  localStorage.setItem(
    "stage",
    data.userAuthentication.principal.applicant.applicationProfile
      .applicationStage.stage
  );

  return true;
}
