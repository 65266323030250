import React, { Component } from "react";
// import Box from "@mui/material/Box";
// import Tab from "@mui/material/Tab";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// Redux
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// availity-reactstrap-validation
import "bootstrap/dist/css/bootstrap.min.css";

import params from "../../util/api_routes/params";
import api from "../../util/api";
import { apiError, checkLogin } from "../../store/actions";
import localStorageSetup from "../../util/setupLocalStorage";

import {
  authenticateUser,
  getUserDetails,
} from "./services/authentication-service";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      fields: {},
      errors: {},
      redirect: false,
      requirements: [],
      isFetching: false,
      network_error: "",
      credentialError: "",
      hide: true,
    };
  }

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  hideSwitch = (ev) => {
    this.setState({ hide: !this.state.hide });
  };

  // use this to login
  // also convert this to a function component
  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let user = null;
      const response = await authenticateUser({
        username: this.state.username,
        password: this.state.password,
      });

      if (response.status === 200) {
        user = await getUserDetails(response.data.access_token);
      }
      if (user.status === 200) {
        console.log(user);
        let status = await localStorageSetup(user.data.data);
        console.log(status);
        if (status) {
          this.setState({
            redirect: true,
          });
          window.location.reload();
        }
      }
    } catch (error) {
      this.setState({ username: "", password: "" });
      this.setState({ credentialError: "Wrong Username or Password" });
    }
  };

  authenticate = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let payLoad = {
        username: this.state.username,
        password: this.state.password,
      };
      try {
        await api
          .post(params.USER_LOGIN, payLoad)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.stsCode === "0000") {
                // call local storage setup
                // console.log(res.data);
                const status = localStorageSetup(res.data);

                if (status === true) {
                  this.setState({
                    redirect: true,
                  });
                }
              }
              if (res.data.stsCode === "0001") {
                this.setState({ username: " ", password: " " });
                this.setState({ credentialError: res.data.stsMessage });
              }
              if (res.data.stsCode === "0002") {
                this.setState({ username: " ", password: " " });
                this.setState({ credentialError: res.data.stsMessage });
              }
              if (res.data.stsCode === "0003") {
                this.setState({ username: "", password: "" });
                this.setState({ credentialError: res.data.stsMessage });
              }
            }
          })
          .catch((error) => {
            // console.log(error);
          });
      } catch (error) {}
    }
  };

  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["username"] || typeof fields["username"] === undefined) {
      formIsValid = false;
      errors["username"] = "* Wrong email or password.";
    }
    if (!fields["password"] || typeof fields["password"] === undefined) {
      formIsValid = false;
      errors["username"] = "* Wrong email or password.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  componentDidMount() {
    this.props.apiError("");
    this.getEntryQualification();
    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  getEntryQualification = async () => {
    this.setState({ isFetching: true });
    await api
      .get(params.PROGRAMME_REQUIREMENT)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              network_error: false,
              isFetching: false,
              requirements: res.data.data,
            });
          } else {
          }
        } else {
          this.setState({ network_error: false, isFetching: false });
        }
      })
      .catch((error) => {});
  };

  render() {
    const list_style = {
      padding: "10px",
    };

    const { hide } = this.state;

    return (
      <React.Fragment>
        <div>
          <div className="row">
            <br />
          </div>

          <div className="col-sm-12">
            <div className="card">
              <div className="card-header"></div>
              <div className="card-block">
                <div className="row">
                  <div className="col-lg-12 col-xl-8">
                    <Tabs
                      defaultActiveKey="home"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="home" title="Home">
                        <p className="m-0 paragaraph-justification">
                          Dar es Salaam Maritime Institute (DMI) was established
                          by Act of Parliament No. 22 of 1991 to cater for
                          greater needs of Shipping Industry in the region. The
                          Institute (DMI) originated from what was known as Dar
                          es Salaam Maritime Training Unit (DMTU) which was
                          established on the 3rd July 1978 as a training wing of
                          Tanzania Coastal Shipping Line (TACOSHILI) to fulfil
                          the need of well-trained seafarers.DMI is the Centre
                          of Excellence in Maritime Education and Training in
                          the East African region. The centre has qualified
                          Personnel and provides quality education, which suits
                          market needs in the aspect of being employed and
                          self-employment in maritime industry. DMI offers
                          programmes accredited by NACTVET (NTA Level 4-9) and
                          TASAC (Certificate of Competency – CoC and Short
                          courses).
                        </p>
                        <p className="m-0">
                          <b>The Institute offers:</b>
                        </p>
                        <p className="m-0" style={list_style}>
                          <li>Basic Technician Certificate Programmes</li>
                          <li>Ordinary Diploma Programmes</li>
                          <li>Bachelor Degree Programmes</li>
                          <li>Masters Degree Programmes</li>
                          <li>Certificate of Competence(CoC)</li>
                          <li>Short Courses</li>
                        </p>
                        <p className="m-0 paragaraph-justification">
                          The Institute offers a variety of programmes which
                          provide vast opportunities to prospective students
                          interested to achieve Certificate, Diploma, Bachelor
                          Degree, Master’s Degrees and Certificate of Competency
                          (CoC). The Institute also offers STCW short course
                          programmes for seafarers.<br></br>
                        </p>
                      </Tab>
                      <Tab eventKey="qualification" title="Entry Qualification">
                        {this.state.requirements.length === 0 &&
                        this.state.isFetching === true ? (
                          <div></div>
                        ) : (
                          <div>
                            {this.state.requirements.length === 0 &&
                            this.state.isFetching === false ? (
                              <div className="m-0">
                                <label className="badge badge-warning">!</label>
                                &nbsp; Currently, There is no any Notification
                                any entry qualifications
                              </div>
                            ) : (
                              <div
                                style={{
                                  overflowY: "auto",
                                  height: "400px",
                                  paddingRight: "5px",
                                }}
                                className="scroll"
                              >
                                {this.state.requirements.map((item, i) => (
                                  <div key={i}>
                                    <p className="m-0" key={i}>
                                      <b key={i}>{item.programName}</b>
                                    </p>
                                    <ol className="m-0">
                                      {item.qualifications.length > 0 ? (
                                        <>
                                          {item.qualifications.map((i, y) => (
                                            <li
                                              className="li-style"
                                              key={i + y}
                                            >
                                              {i.entryQual}
                                            </li>
                                          ))}
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <div className="m-0">
                                            <label className="badge badge-warning">
                                              !
                                            </label>
                                            &nbsp; Currently, there is
                                            qualifications for this Programme
                                          </div>
                                        </>
                                      )}
                                    </ol>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </Tab>
                      <Tab
                        eventKey="instruction"
                        // title="Instructions and
                        //   Registration"
                        title="Instructions"
                      >
                        {/* <p className="m-0">Registration</p> */}
                        <p className="m-0 paragaraph-justification">
                          Application procedure
                        </p>
                        {/* <p className="m-0">
                          <b>The Institute is offering</b>
                        </p> */}
                        <p className="m-0" style={list_style}>
                          <li> Google DMI-SOMAS Online application link</li>
                          <li>
                            Read through all the entry requirements given in
                            each programme
                          </li>
                          <li>Select Long or Short courses</li>
                          <li>Choose level that you want to apply</li>
                          <li>
                            Enter your form four index number and completion
                            year
                          </li>
                          <li>Create your account then login</li>
                          <li>Fill next kin information as required</li>
                          <li>Fill primary education details</li>
                          <li>
                            Fill all other steps and complete the application
                          </li>
                        </p>
                        {/* <p className="m-0 paragaraph-justification">
                          The Institute offers a variety of programmes which
                          provide vast opportunities to prospective students
                          interested to achieve Certificate, Diploma, Bachelor
                          Degree, Master’s Degrees and Certificate of Competency
                          (CoC). The Institute also offers STCW short course
                          programmes for seafarers.<br></br>
                        </p> */}
                      </Tab>
                    </Tabs>
                  </div>

                  {/** ------------  Login Form ---------- **/}
                  <div className="col-lg-12 col-xl-4">
                    <div className="block login">
                      <div className="block-title">
                        <br></br>
                        <h6>
                          <i className="ti-lock"></i> Login In
                        </h6>
                        <br />
                      </div>

                      {this.state.redirect && <Redirect to="/dashboard" />}

                      {this.state.errors.username ||
                      this.state.errors.password ? (
                        <div className="errorMsg">
                          {this.state.errors.username}{" "}
                          <i className="ti-hand-point-down"></i>
                        </div>
                      ) : null}

                      {this.state.credentialError ? (
                        <div className="errorMsg">
                          {this.state.credentialError}{" "}
                          <i className="ti-hand-point-down"></i>
                        </div>
                      ) : null}
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            name="username"
                            className={
                              this.state.errors.username
                                ? "input-group-danger form-control"
                                : "form-control"
                            }
                            type="text"
                            value={this.state.username}
                            onChange={this.handleInputState}
                            autoComplete="off"
                            placeholder="Email"
                          />
                        </div>

                        <div className="form-group">
                          <label>Password</label>
                          <input
                            // type="password"
                            type={hide ? "password" : "text"}
                            name="password"
                            value={this.state.password}
                            onChange={this.handleInputState}
                            className="form-control"
                            placeholder="Password"
                          />
                          <span
                            className="password__show"
                            onClick={this.hideSwitch}
                            style={{
                              position: "absolute",
                              right: 20,
                            }}
                          >
                            {hide ? <FaEye /> : <FaEyeSlash />}
                          </span>
                        </div>

                        <div className="form-group">
                          <div className="registerlink">
                            <button className="btn btn-info btn-sm">
                              {" "}
                              Sign In
                            </button>{" "}
                            <Link to={"/sign-up"}>Create Account</Link>
                            &nbsp; ||
                            <Link to={"/reset-account"}>
                              {" "}
                              Forgot Password ?
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
