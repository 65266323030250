import axios from "api/axios";

const GET_ADMISSION_LETTER = "/api/admission/letter";
const DELETE_REFERENCE = "/api/permit-info/delete/";
const VIEW_ADMISSION_LETTER = "/api/permit-info/preview";

const getAdmissionPackage = async (params) => {
  return await axios.get(`${GET_ADMISSION_LETTER}`, { params });
};

// const deleteReferenceDetails = async (id) => {
//   return await axios.delete(`${DELETE_REFERENCE}${id}`);
// };

const vieAdmissionPackage = async (params) => {
  return await axios.get(`${VIEW_ADMISSION_LETTER}`, { params });
};

export { getAdmissionPackage, vieAdmissionPackage };
