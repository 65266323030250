import React, { Component } from "react";
import {
  FaRegEdit,
  FaUpload,
  FaRegWindowRestore,
  FaRegFrown,
} from "react-icons/fa";
import { Button, Container, Modal } from "react-bootstrap";
import Alert from "@mui/material/Alert";
import ReactLoading from "react-loading";
import api from "../../util/api";
import params from "../../util/api_routes/params";
import toTitleconverttoTitle from "../../util/toTitleConveter";
import tzPhoneNumberValidator from "../../util/tzPhoneNumberValidator";

class PerformUnconfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yesIsOpen: false,
      noIsOpen: false,
      redirect: false,
      unconfirmation_code: "",
      fields: {},
      errors: {},
      phone: "",
    };
  }
  openModelForm = (e) => {
    e.preventDefault();
    // if (this.validateQuestion()) {
    //   if (this.state.relative !== "") {
    this.setState({ yesIsOpen: true });
    this.setState({ noIsOpen: false });
    //   }
    // }
  };

  closeModal = () => {
    this.setState({
      yesIsOpen: false,
      noIsOpen: false,
      loading: "",
      fields: {},
      errors: {},
      network_error: false,
      particular_exists: false,
      selectedFile: "",
      setIsSelected: false,
      isSelected: false,
    });
  };

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    console.log(this.state.fields);
  };

  validatePhone = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["phone"] || typeof fields["phone"] === undefined) {
      formIsValid = false;
      errors["phone"] = "* Please fill the phone number.";
    } else if (tzPhoneNumberValidator(fields["phone"]) === false) {
      formIsValid = false;
      errors["phone"] = "* Phone number is wrong,please check and re-type.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  validateTcuCode = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["unconfirmation_code"]) {
      formIsValid = false;
      errors["unconfirmation_code"] =
        "* Please fill in the unconfirmation code.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  requestconfirmationCode = async (e) => {
    e.preventDefault();
    if (this.validatePhone()) {
      let payLoad = {
        phone: this.state.phone,
      };
    }
  };

  submitUnConfirmationCode = async (e) => {
    e.preventDefault();
    if (this.validateTcuCode()) {
      let payLoad = {
        admno: localStorage.getItem("id"),
        confirmationCode: this.state.unconfirmation_code,
      };
      await api
        .post(params.PERFORM_TCU_UNCONFIRMATION, payLoad)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                info_loading: false,
                network_error: false,
              });
              alert(res.data.stsMessage);
              window.location.reload(false);
            } else {
              // this.setState({
              //   network_error: false,
              //   info_loading: false,
              // });
              alert(res.data.stsMessage);
              window.location.reload(false);
            }
          }
        })
        .catch((err) => {
          this.setState({
            network_error: true,
            info_loading: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div>
          {/* <form onSubmit={this.requestconfirmationCode}>
            <div className="form-group">
              <label>
                {" "}
                1. Enter phone number to request unuconfirmation code from{" "}
                <span style={{ color: "red" }}>TCU</span>
                <font className="label-comment">*</font>
              </label>
              <input
                type="text"
                name="phone"
                className="form-control input-placeholder"
                value={this.state.phone}
                onChange={this.handleInputState}
                placeholder="Phone"
              ></input>

              {this.state.errors.phone ? (
                <div className="errorMsg">{this.state.errors.phone}</div>
              ) : null}
            </div>
            <div className="form-group">
              <Button
                type="submit"
                variant="primary"
                className="btn btn-round  btn-sm"
              >
                <FaRegWindowRestore />
                {"     "} Request Unconfirmation Code
              </Button>
            </div>
          </form> */}
        </div>
        <div>
          <form onSubmit={this.openModelForm}>
            <div className="form-group">
              <label>
                {" "}
                {/* 2. Now click the button below to submit the unconfirmation code
                received in your mobile. */}
                2. Use the same confirmation code received earlier to unconfirm
                <font className="label-comment">*</font>
              </label>
              <div className="form-group">
                <Button
                  type="submit"
                  variant="primary"
                  className="btn btn-round  btn-sm"
                >
                  <FaRegFrown />
                  {"     "} Submit Unconfirmation Code
                </Button>
              </div>
            </div>
          </form>
        </div>

        <Modal size="lg" show={this.state.yesIsOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <div className="float-center card-header-title">
              <FaRegEdit />
              Fill in unconfirmation code
            </div>
          </Modal.Header>
          <form onSubmit={this.submitUnConfirmationCode}>
            <Modal.Body>
              {this.state.loading === true ? (
                <>
                  <ReactLoading type="cylon" color="#000" />
                </>
              ) : null}
              {this.state.network_error === true ? (
                <>
                  <Alert onClose={this.closeNetworkWarning} severity="error">
                    You are facing netwok problems — Please try again later or
                    contact the system administrator!
                  </Alert>
                </>
              ) : null}

              <div className="form-group">
                <label>
                  Fill in the unconfrimation code
                  <font className="label-comment">*</font>
                </label>
                <input
                  type="text"
                  name="unconfirmation_code"
                  className="form-control input-placeholder"
                  value={this.state.confimation_code}
                  onChange={this.handleInputState}
                  placeholder="tcu unconfirmation code"
                ></input>
                {this.state.errors.unconfirmation_code ? (
                  <div className="errorMsg">
                    {this.state.errors.unconfirmation_code}
                  </div>
                ) : null}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                className="btn btn-round  btn-sm"
              >
                <FaUpload />
                {"     "}Submit
              </Button>
              <Button
                variant="secondary"
                className="btn btn-round  btn-sm btn btn-round"
                onClick={this.closeModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}

export default PerformUnconfirmation;
