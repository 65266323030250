import React, { Component } from "react";
import { Container } from "reactstrap";
import ApplicantProfile from "../../components/profile/ApplicantProfile";

class StarterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [{ title: "Dashboard", link: "#" }],
      user_data: [],
    };
  }
  componentDidMount() {
    this.getApplicantProfile();
  }
  getApplicantProfile = () => {
    this.setState({ user_data: JSON.parse(localStorage.getItem("authUser")) });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <ApplicantProfile user_data={this.state.user_data} />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default StarterPage;
