import React, { Component } from "react";
import { FaRegHandPointDown, FaRegHandPointRight } from "react-icons/fa";
class ReferenceFillingInstruction extends Component {
  render() {
    return (
      <>
        {this.props.status === "right" ? (
          <>
            <p>
              <b>Instructions</b>
            </p>
            <hr />

            <ul>
              Please fill your 'Study Permit' and 'Passport' information through
              answering question. <FaRegHandPointRight size={25} />
              <br></br>
              <li>
                If you don't have 'Study Permit' and 'Passport', please select
                No and confirm
              </li>
              <li style={{ paddingTop: "5px" }}>
                <b>Note</b>. The fields with * are mandatory
              </li>
            </ul>
          </>
        ) : (
          <>
            <p>
              <b>Instructions</b>
            </p>
            <hr />

            <ul>
              Please fill your study permit and passport information through
              answering question.
              <li>
                If you don't have 'Study Permit' and 'Passport', please select
                No and confirm
              </li>
              <li style={{ paddingTop: "5px" }}>
                <b>Note</b>. The fields with * are mandatory
              </li>
              <br></br>
              <li>
                The form can found below <FaRegHandPointDown size={25} />
              </li>
              <br></br>
            </ul>
          </>
        )}
      </>
    );
  }
}
export default ReferenceFillingInstruction;
