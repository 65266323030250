const signUpState = {
  index_number: "",
  year: "",
  intake_id: "",
  intake_name: "",
  academic_year_id: "",
  level_id: "",
  level_name: "",
  academic_year: "",
  indexno: "",
  necta: [],
  phone: "",
  username: "",
  password: "",
  dob: "",
  date: "",
  dayofBirth: "",
  month: "",
  yearofBirthday: "",
  confirmpassword: "",
  display_details: [],
  account_existence_status: false,
  valid_olevel_information: "",
  restricted_indexno: false,
  account_form: false,
  clearable: true,
  loading: "",
  fields: {},
  errors: {},
};
export default signUpState;
