import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactLoading from "react-loading";
import changeStage from "../../../util/changeStage";
import { FaRegCheckCircle, FaPlusCircle, FaSave } from "react-icons/fa";
import { WiDirectionUpRight } from "react-icons/wi";
import yearProvider from "../../../util/yearsProvider";
import indexNumberValidator from "../../../util/indexNumberValidator";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import { withRouter } from "react-router";
class AddSecondaryEducationComp extends Component {
  state = {
    olevel_question: "",
    index_number: "",
    year: "",
    yesIsOpen: false,
    noIsOpen: false,
    redirect: false,
    loading: "",
    fields: {},
    errors: {},
  };

  closeModal = () =>
    this.setState({
      yesIsOpen: false,
      noIsOpen: false,
      index_number: "",
      year: "",
      answer: "",
      olevel_question: "",
      loading: "",
      errors: {},
    });

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    if (e.target.name === "olevel_question") {
      if (e.target.value === "") {
      }
      if (e.target.value === "1") {
        this.setState({ yesIsOpen: true });
      }
      if (e.target.value === "2") {
        this.setState({ noIsOpen: true });
      }
    }
  };

  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["index_number"]) {
      formIsValid = false;
      errors["index_number"] = "* Please fill the olevel index number.";
    }
    if (
      fields["index_number"] &&
      indexNumberValidator(fields["index_number"]) === false
    ) {
      formIsValid = false;
      errors["index_number"] = "* Wrong index number format.";
    }
    if (!fields["year"]) {
      formIsValid = false;
      errors["year"] = "* Please select completion year.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  validateNoForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["answer"]) {
      formIsValid = false;
      errors["answer"] = "* Please select the answer.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  submitYesOlvelInformation = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true });
      let payLoad = {
        indexno: this.state.index_number,
        year: this.state.year,
        applProfileId: parseInt(localStorage.getItem("id")),
      };
      await api
        .post(params.CHECKING_ACCOUNT_EXISTENCE, payLoad)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                index_number: "",
                year: "",
                yesIsOpen: false,
                noIsOpen: false,
                loading: false,
                redirect: true,
                answer: "",
              });
              window.location.reload();
              this.props.history.push("/education/secondary-school");
            } else if (res.data.stsCode === "0007") {
              // console.log(res.data);
              // alert("Your particulars do not match");
              alert(res.data.stsMessage);
              window.location.reload();
            } else if (res.data.stsCode === "0001") {
              //0008
              // alert("Data not found from NECTA");
              alert(res.data.stsMessage);
              window.location.reload();
            } else {
              this.setState({
                network_error: true,
                answer: "",
                loading: false,
              });
            }
            ///
          } else {
            this.setState({ account_existence_status: true, answer: "" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  submitNoOlvelInformation = async (e) => {
    e.preventDefault();
    if (this.validateNoForm()) {
      if (this.state.answer === "1") {
        let payLoad = {
          applicationProfileId: parseInt(localStorage.getItem("id")),
          stageId: 5,
        };
        let result = await changeStage(payLoad);
        if (result === 1) {
          this.setState({
            noIsOpen: false,
            index_number: "",
            year: "",
            answer: "",
            olevel_question: "",
            loading: "",
            errors: {},
            yesIsOpen: false,
          });
          this.props.history.push("/education/high-school");
        }
      }
      if (this.state.answer === "2") {
        this.setState({
          noIsOpen: false,
          index_number: "",
          year: "",
          answer: "",
          olevel_question: "",
          loading: "",
          errors: {},
          yesIsOpen: true,
        });
      }
    }
  };

  render() {
    const yearList = yearProvider().map((x) => {
      return (
        <option key={x} value={x}>
          {x}
        </option>
      );
    });
    return (
      <>
        <form>
          <div className="form-group">
            <label>
              Do you have another ordinary level certificate apart from the
              above (
              <WiDirectionUpRight size={40} />) ?{" "}
              <font className="label-comment">*</font>
            </label>
            <select
              name="olevel_question"
              className="selection-box  input-placeholder"
              value={this.state.name}
              onChange={this.handleInputState}
            >
              <option value=""> -- Select the answer -- </option>
              <option value="1">Yes</option>
              <option value="2">No</option>
            </select>
          </div>
        </form>

        <Modal size="lg" show={this.state.yesIsOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <div className="float-center card-header-title">
              <FaPlusCircle /> &nbsp; Add Secondary School Education
            </div>
          </Modal.Header>
          <form onSubmit={this.submitYesOlvelInformation}>
            <Modal.Body>
              {this.state.loading === true ? (
                <>
                  <ReactLoading type="cylon" color="#000" />
                </>
              ) : null}

              <div className="form-group">
                <label className="label-class">
                  O Level Index Number/ NECTA Equivalence Number (Other Sitting
                  Index Number)
                  <font className="label-comment">*</font>
                </label>
                <input
                  type="text"
                  name="index_number"
                  value={this.state.name}
                  onChange={this.handleInputState}
                  className="form-control"
                  placeholder="E.g. SXXXX-XXXX  or EQXXXXXXXXXXX"
                />
                {this.state.errors.index_number ? (
                  <div className="errorMsg">
                    {this.state.errors.index_number}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label className="label-class">
                  Completion Year <font className="label-comment">*</font>
                </label>
                <select
                  name="year"
                  className="selection-box  input-placeholder"
                  value={this.state.year}
                  onChange={this.handleInputState}
                >
                  <option key="" value="">
                    {" "}
                    -- Selection Completion Year --
                  </option>
                  {yearList}
                </select>
                {this.state.errors.year ? (
                  <div className="errorMsg">{this.state.errors.year}</div>
                ) : null}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                className="btn btn-round  btn-sm"
              >
                <FaSave />
                {"     "}Save
              </Button>
              <Button
                variant="secondary"
                className="btn btn-round  btn-sm btn btn-round"
                onClick={this.closeModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal size="lg" show={this.state.noIsOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <div className="float-center card-header-title">
              <FaPlusCircle />
              Secondary School Education (Ordinary level Education)
            </div>
          </Modal.Header>
          <form onSubmit={this.submitNoOlvelInformation}>
            <Modal.Body>
              <div className="form-group">
                <label>
                  Are you sure you have not attended any other secondary school?
                  {/* Please select
                  <font style={{ color: "green", size: "12px" }}> Yes</font> if
                  you have mentioned all the school you attended otherwise
                  select <b style={{ color: "#ff9800" }}>No</b> to continue
                  filling another school answer to confirm
                  <font className="label-comment">*</font> */}
                </label>
                <select
                  name="answer"
                  className="selection-box  input-placeholder"
                  value={this.state.name}
                  onChange={this.handleInputState}
                >
                  <option value=""> -- Select the answer -- </option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </select>
                {this.state.errors.answer ? (
                  <div className="errorMsg">{this.state.errors.answer}</div>
                ) : null}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                variant="primary"
                className="btn btn-round  btn-sm"
              >
                <FaRegCheckCircle />
                {"     "}Confirm
              </Button>
              <Button
                variant="secondary"
                className="btn btn-round  btn-sm btn btn-round"
                onClick={this.closeModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}
const AddSecondaryEducation = withRouter(AddSecondaryEducationComp);

export default AddSecondaryEducation;
