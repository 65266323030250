import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import { deleteReferenceDetails, viewReferenceDetails } from "./services";
class DisplayReference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }

  FunRemove = async (id) => {
    if (window.confirm("Do you want to remove?")) {
      const res = await deleteReferenceDetails(id);
      if (res.data.status === 200) {
        window.location.reload();
      }
    }
  };

  viewReference = async (id) => {
    let payload = {
      permit_id: id,
    };
    const res = await viewReferenceDetails(payload);
    if (res.status === 200) {
      if (res.data.stsCode === "0000") {
        this.setState({
          reference: res.data.data.attachment,
          viewId: id,
          viewRef: true,
        });
      } else {
        alert(res.data.stsMessage);
      }
    }
  };

  closeReference = async () => {
    this.setState({
      viewRef: false,
    });
  };

  render() {
    return (
      <div style={{ paddingTop: "40px" }}>
        <Accordion defaultActiveKey="0">
          {this.state.stage === 2 ? (
            <>
              {this.props.reference_data.map((option, i) => (
                <div key={i}>
                  {i === 0 ? (
                    <Accordion.Item eventKey={i} key={i + "Accordion"}>
                      <Accordion.Header
                        className="accordinHeader"
                        key={i + "accordinHeader"}
                      >
                        {option.permitType} : {option.permitNo}{" "}
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            this.FunRemove(option.id);
                          }}
                        >
                          Detete
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.viewReference(option.id);
                          }}
                        >
                          View
                        </button>
                        {this.state.viewRef &&
                        option.id === this.state.viewId ? (
                          <button
                            className="btn btn-warning"
                            onClick={() => {
                              this.closeReference(option.id);
                            }}
                          >
                            Close
                          </button>
                        ) : null}
                        {this.state.viewRef &&
                        option.id === this.state.viewId ? (
                          <embed
                            width={600}
                            height={800}
                            src={`data:application/pdf;base64,${this.state.reference}`}
                          />
                        ) : null}
                      </Accordion.Header>
                      <Accordion.Body
                        className="accordionBody"
                        key={i + "accordionBody"}
                      >
                        {/* {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null} */}
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    <>
                      <Accordion.Item eventKey={i} key={i + "Accordion"}>
                        <Accordion.Header
                          className="accordinHeader"
                          key={i + "accordinHeader"}
                        >
                          {option.permitType} : {option.permitNo}{" "}
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              this.FunRemove(option.id);
                            }}
                          >
                            Detete
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              this.viewReference(option.id);
                            }}
                          >
                            View
                          </button>
                          {this.state.viewRef &&
                          option.id === this.state.viewId ? (
                            <button
                              className="btn btn-warning"
                              onClick={() => {
                                this.closeReference(option.id);
                              }}
                            >
                              Close
                            </button>
                          ) : null}
                          {this.state.viewRef &&
                          option.id === this.state.viewId ? (
                            <embed
                              width={600}
                              height={800}
                              src={`data:application/pdf;base64,${this.state.reference}`}
                            />
                          ) : null}
                        </Accordion.Header>
                        <Accordion.Body
                          className="accordionBody"
                          key={i + "accordionBody"}
                        >
                          {/* {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null} */}
                        </Accordion.Body>
                      </Accordion.Item>
                    </>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {" "}
              {this.props.reference_data.map((option, i) => (
                <div key={i}>
                  {i === 0 ? (
                    <Accordion.Item eventKey="0" key={i + "Accordion"}>
                      <Accordion.Header
                        className="accordinHeader"
                        key={i + "accordinHeader"}
                      >
                        {option.permitType} : {option.permitNo}{" "}
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            this.FunRemove(option.id);
                          }}
                        >
                          Detete
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.viewReference(option.id);
                          }}
                        >
                          View
                        </button>
                        {this.state.viewRef &&
                        option.id === this.state.viewId ? (
                          <button
                            className="btn btn-warning"
                            onClick={() => {
                              this.closeReference(option.id);
                            }}
                          >
                            Close
                          </button>
                        ) : null}
                        {this.state.viewRef &&
                        option.id === this.state.viewId ? (
                          <embed
                            width={600}
                            height={800}
                            src={`data:application/pdf;base64,${this.state.reference}`}
                          />
                        ) : null}
                      </Accordion.Header>
                      <Accordion.Body
                        className="accordionBody"
                        key={i + "accordionBody"}
                      >
                        {/* {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null} */}
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    <Accordion.Item eventKey={i} key={i + "Accordion"}>
                      <Accordion.Header
                        className="accordinHeader"
                        key={i + "accordinHeader"}
                      >
                        {option.permitType} : {option.permitNo}{" "}
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            this.FunRemove(option.id);
                          }}
                        >
                          Detete
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.viewReference(option.id);
                          }}
                        >
                          View
                        </button>
                        {this.state.viewRef &&
                        option.id === this.state.viewId ? (
                          <button
                            className="btn btn-warning"
                            onClick={() => {
                              this.closeReference(option.id);
                            }}
                          >
                            Close
                          </button>
                        ) : null}
                        {this.state.viewRef &&
                        option.id === this.state.viewId ? (
                          <embed
                            width={600}
                            height={800}
                            src={`data:application/pdf;base64,${this.state.reference}`}
                          />
                        ) : null}
                      </Accordion.Header>
                      <Accordion.Body
                        className="accordionBody"
                        key={i + "accordionBody"}
                      >
                        {/* {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null} */}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </div>
              ))}
            </>
          )}
        </Accordion>
      </div>
    );
  }
}

export default DisplayReference;
