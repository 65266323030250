import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import { getAdmissionPackage } from "./services";
class AdmissionPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: parseInt(localStorage.getItem("stageid")),
      admno: parseInt(localStorage.getItem("id")),
    };
  }

  //   FunRemove = async (id) => {
  //     if (window.confirm("Do you want to remove?")) {
  //       const res = await deleteReferenceDetails(id);
  //       if (res.data.status === 200) {
  //         window.location.reload();
  //       }
  //     }
  //   };

  getPackage = async (id) => {
    let payload = {
      admno: this.state.admno,
    };
    const res = await getAdmissionPackage(payload);
    if (res.status === 200) {
      if (res.data.stsCode === "0000") {
        this.setState({
          admissionLetter: res.data.data,
          viewAdmno: true,
        });
      } else {
        alert(res.data.stsMessage);
      }
    }
  };

  closeReference = async () => {
    this.setState({
      viewAdmno: false,
    });
  };

  render() {
    return (
      <div style={{ paddingTop: "40px" }}>
        <a href="#" onClick={(e) => this.getPackage()}>
          Admission Letter
        </a>
        {this.state.viewAdmno ? (
          <button
            className="btn btn-warning"
            onClick={() => {
              this.closeReference();
            }}
          >
            Close
          </button>
        ) : null}
        {this.state.viewAdmno ? (
          <embed
            width={400}
            height={600}
            src={`data:application/pdf;base64,${this.state.admissionLetter}`}
          />
        ) : null}
      </div>
    );
  }
}

export default AdmissionPackage;
