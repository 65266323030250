import React, { Component } from "react";
import { Container } from "reactstrap";
import params from "../../../util/api_routes/params";
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "react-router";
import {
  FaUpload,
  FaRegEdit,
  FaRegHandPointRight,
  FaArrowAltCircleRight,
} from "react-icons/fa";
import changeStage from "../../../util/changeStage";
import Alert from "@mui/material/Alert";
import ReactLoading from "react-loading";
//import EditPrimaryEducation from "./edit_primary_education";
import api from "../../../util/api";
class PrimaryEducationComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [{ title: "Dashboard", link: "#" }],
      primary_school: [],
      application_id: "",
      school: "",
      year: "",
      fields: {},
      errors: {},
      networkError: "",
      apiError: "",
      stage: parseInt(localStorage.getItem("stageid")),
      addingLoading: "",
      isOpen: false,
      addingError: "",
      addingnetworkError: "",
    };
  }

  openModal = () => this.setState({ isOpen: true, errors: "" });
  closeModal = () => this.setState({ isOpen: false, errors: "" });

  componentDidMount() {
    this.getPrimaryEducation();
  }

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  //add primary Education
  submitPrimaryEducation = async (e) => {
    e.preventDefault();
    if (this.validatesubmissionForm()) {
      this.setState({ addingLoading: true });
      let payLoad = {
        profileId: parseInt(localStorage.getItem("id")),
        schoolName: this.state.school,
        yearCompleted: this.state.year,
      };
      await api
        .post(params.ADD_PRIMARY_EDUCATION, payLoad)
        .then((res) => {
          this.setState({ addingLoading: false });
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.getPrimaryEducation();
            } else {
              this.setState({ addingError: true });
            }
          } else {
            this.setState({ addingError: true });
          }
        })
        .catch((err) => {
          this.setState({ addingnetworkError: true });
          this.setState({ addingLoading: false });
        });
    }
  };
  // Edit primary Education
  submitEditedPrimaryEducation = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ addingLoading: true });
      let payLoad = {
        profileId: parseInt(localStorage.getItem("id")),
        schoolName: this.state.school,
        yearCompleted: this.state.year,
        id: this.state.id,
      };
      // console.log("oi");
      // this.getPrimaryEducation();
      await api
        .post(params.EDIT_PRIMARY_EDUCATION, payLoad)
        .then((res) => {
          this.setState({ addingLoading: false });
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.getPrimaryEducation();
              window.location.reload();
            } else {
              this.setState({ addingError: true });
            }
          } else {
            this.setState({ addingError: true });
          }
        })
        .catch((err) => {
          this.setState({ addingnetworkError: true });
          this.setState({ addingLoading: false });
        });
      // window.location.reload();
      // console.log("huree");
    }
  };

  nextToConfirmationSubmission = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 4,
    };
    let result = await changeStage(payLoad);
    if (result === 1) {
      this.setState({ loading: false, network_error: false });
      this.props.history.push("/education/secondary-school");
    } else {
    }
  };
  // View current added primary Education Information
  getPrimaryEducation = async () => {
    await api
      .get(
        params.PRIMARY_EDUCATION + "/" + parseInt(localStorage.getItem("id"))
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              primary_school: res.data.data,
              application_id: res.data.data.profileId,
              school: res.data.data.schoolName,
              year: Number(res.data.data.yearCompleted),
              id: res.data.data.id,
              networkError: false,
              addingError: false,
              apiError: false,
            });
            console.log(res.data);
            console.log(this.state.stage);
          }
          if (res.data.stsCode === "0003") {
            this.setState({
              primary_school: [],
              networkError: false,
              apiError: false,
            });
          }
        } else {
          this.setState({ networkError: true });
        }
      })
      .catch((err) => {
        this.setState({ apiError: true });
      });
  };

  ///Form validation
  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!this.state.school || typeof this.state.school === undefined) {
      formIsValid = false;
      errors["school"] = "* Please fill in the school.";
    }
    if (!this.state.year || typeof this.state.year === undefined) {
      formIsValid = false;
      errors["year"] = "* Please select year";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  validatesubmissionForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["school"] || typeof fields["school"] === undefined) {
      formIsValid = false;
      errors["school"] = "* Please fill the school name.";
    }
    if (!fields["year"] || typeof fields["year"] === undefined) {
      formIsValid = false;
      errors["year"] = "* Please select completion year";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  render() {
    // console.log(parseInt(localStorage.getItem("stageid")));
    let allYears = [];
    let thisYear = new Date().getFullYear();

    //console.log(thisYear - 10);
    let start = thisYear - 4; // initiate year
    for (let x = start; x > 1970; x--) {
      allYears.push(x);
    }
    const yearList = allYears.map((x) => {
      return (
        <option key={x} value={x}>
          {x}
        </option>
      );
    });

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  Primary Education
                </div>
                <div className="float-right card-header-title">
                  {/* Application for : {localStorage.getItem("academicYear")} */}
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-body">
                <div className="block">
                  {this.state.apiError === true ||
                  this.state.networkError === true ? (
                    <>Loading ......</>
                  ) : (
                    <>
                      {Object.keys(this.state.primary_school).length > 0 ? (
                        <>
                          {this.state.stage === 3 ? (
                            <>
                              {" "}
                              <div className="row">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-8">
                                  <div>
                                    <table className="table table-bordered">
                                      <thead></thead>
                                      <tbody>
                                        <tr>
                                          <th
                                            style={{
                                              backgroundColor: "#f1f5f7",
                                              width: "20%",
                                              fontWeight: "bold",
                                              fontFamily: "Helvetica",
                                            }}
                                          >
                                            School Name
                                          </th>
                                          <td style={{ fontStyle: "italic" }}>
                                            {this.state.school}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th
                                            style={{
                                              backgroundColor: "#f1f5f7",
                                              width: "20%",
                                              fontWeight: "bold",
                                              fontFamily: "Helvetica",
                                            }}
                                          >
                                            Completion Year
                                          </th>
                                          <td style={{ fontStyle: "italic" }}>
                                            {this.state.year}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                  <div>
                                    <Modal
                                      size="lg"
                                      show={this.state.isOpen}
                                      onHide={this.closeModal}
                                    >
                                      <Modal.Header closeButton>
                                        <div className="float-center card-header-title">
                                          <FaRegEdit />
                                          Primary School Education
                                        </div>
                                      </Modal.Header>
                                      <form
                                        onSubmit={
                                          this.submitEditedPrimaryEducation
                                        }
                                      >
                                        <Modal.Body>
                                          <div className="form-group">
                                            <label>School Name </label>
                                            <input
                                              name="school"
                                              className="form-control"
                                              value={this.state.school}
                                              onChange={this.handleInputState}
                                            />
                                            {this.state.errors.school ? (
                                              <div className="errorMsg">
                                                {this.state.errors.school}
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="form-group">
                                            <label>Completion Year *</label>
                                            <select
                                              name="year"
                                              className="selection-box  input-placeholder"
                                              value={this.state.year}
                                              onChange={this.handleInputState}
                                            >
                                              <option key=" ">
                                                {" "}
                                                Selection Completion Year
                                              </option>
                                              {yearList}
                                            </select>
                                            {this.state.errors.year ? (
                                              <div className="errorMsg">
                                                {this.state.errors.year}
                                              </div>
                                            ) : null}
                                          </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="btn btn-round  btn-sm"
                                          >
                                            <FaUpload />
                                            {"     "}Save
                                          </Button>
                                          <Button
                                            variant="secondary"
                                            className="btn btn-round  btn-sm btn btn-round"
                                            onClick={this.closeModal}
                                          >
                                            Close
                                          </Button>
                                        </Modal.Footer>
                                      </form>
                                    </Modal>
                                  </div>
                                </div>
                                <div className="col-sm-1">
                                  <div style={{ paddingTop: "36px" }}>
                                    {Object.keys(this.state.primary_school)
                                      .length > 0 ? (
                                      <Button
                                        variant="warning"
                                        onClick={this.openModal}
                                        className="btn btn-round  btn-sm btn-warning verification-button"
                                      >
                                        <i className="fa fa-edit fa-sm"></i>{" "}
                                        Edit
                                      </Button>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-11">
                                  <Button
                                    type="submit"
                                    className="btn btn-round  btn-md"
                                    variant="primary"
                                    onClick={this.nextToConfirmationSubmission.bind(
                                      this
                                    )}
                                  >
                                    Continue to Secondary Education{" "}
                                    <FaArrowAltCircleRight size={20} />
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.stage < 3 ? (
                                <>
                                  {" "}
                                  <Alert severity="warning">
                                    Please fill the previous stages Information
                                    {console.log(this.state.stage)}
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  <table className="table table-bordered">
                                    <thead></thead>
                                    <tbody>
                                      <tr>
                                        <th
                                          style={{
                                            backgroundColor: "#f1f5f7",
                                            width: "20%",
                                            fontWeight: "bold",
                                            fontFamily: "Helvetica",
                                          }}
                                        >
                                          School Name
                                        </th>
                                        <td style={{ fontStyle: "italic" }}>
                                          {this.state.school}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th
                                          style={{
                                            backgroundColor: "#f1f5f7",
                                            width: "20%",
                                            fontWeight: "bold",
                                            fontFamily: "Helvetica",
                                          }}
                                        >
                                          Completion Year
                                        </th>
                                        <td style={{ fontStyle: "italic" }}>
                                          {this.state.year}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  {/* Edit change primary info after clicking continue button */}
                                  <div>
                                    <Modal
                                      size="lg"
                                      show={this.state.isOpen}
                                      onHide={this.closeModal}
                                    >
                                      <Modal.Header closeButton>
                                        <div className="float-center card-header-title">
                                          <FaRegEdit />
                                          Primary School Education
                                        </div>
                                      </Modal.Header>
                                      <form
                                        onSubmit={
                                          this.submitEditedPrimaryEducation
                                        }
                                      >
                                        <Modal.Body>
                                          <div className="form-group">
                                            <label>School Name </label>
                                            <input
                                              name="school"
                                              className="form-control"
                                              value={this.state.school}
                                              onChange={this.handleInputState}
                                            />
                                            {this.state.errors.school ? (
                                              <div className="errorMsg">
                                                {this.state.errors.school}
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="form-group">
                                            <label>Completion Year *</label>
                                            <select
                                              name="year"
                                              className="selection-box  input-placeholder"
                                              value={this.state.year}
                                              onChange={this.handleInputState}
                                            >
                                              {/* <option key=" ">
                                                {" "}
                                                Selection Completion Year
                                              </option> */}
                                              {yearList}
                                            </select>
                                            {this.state.errors.year ? (
                                              <div className="errorMsg">
                                                {this.state.errors.year}
                                              </div>
                                            ) : null}
                                          </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="btn btn-round  btn-sm"
                                          >
                                            <FaUpload />
                                            {"     "}Save
                                          </Button>
                                          <Button
                                            variant="secondary"
                                            className="btn btn-round  btn-sm btn btn-round"
                                            onClick={this.closeModal}
                                          >
                                            Close
                                          </Button>
                                        </Modal.Footer>
                                      </form>
                                    </Modal>
                                  </div>
                                  <div className="col-sm-1">
                                    <div style={{ paddingTop: "36px" }}>
                                      {Object.keys(this.state.primary_school)
                                        .length > 0 ? (
                                        <Button
                                          variant="warning"
                                          onClick={this.openModal}
                                          className="btn btn-round  btn-sm btn-warning verification-button"
                                        >
                                          <i className="fa fa-edit fa-sm"></i>{" "}
                                          Edit
                                        </Button>
                                      ) : null}
                                    </div>
                                  </div>
                                  {/* Edit change primary info after clicking continue button */}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {this.state.stage === 3 ? (
                            <>
                              <div className="row">
                                <div className="col-sm-4">
                                  <p>
                                    <b>Instructions</b>
                                  </p>
                                  <hr />
                                  <ul>
                                    Please fill your primary school name and the
                                    year which you completed on your right side
                                    form <FaRegHandPointRight size={20} />
                                    <br></br>
                                    <li style={{ paddingTop: "5px" }}>
                                      <b>Note</b>. The fields with * are
                                      mandatory
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-sm-8">
                                  <br></br>
                                  {this.state.addingnetworkError === true ? (
                                    <div className="alert alert-warning">
                                      <strong>Warning!</strong> Sorry is a
                                      system or network problems.
                                    </div>
                                  ) : null}
                                  {this.state.addingError === true ? (
                                    <div className="alert alert-warning">
                                      <strong>Warning!</strong> Sorry, you have
                                      failed to add primary education
                                    </div>
                                  ) : null}
                                  {this.state.addingError === false ? (
                                    <div className="alert alert-success">
                                      <strong>Success!</strong> Indicates a
                                      successful or positive action.
                                    </div>
                                  ) : null}

                                  {this.state.addingError === false ? (
                                    <div className="alert alert-success">
                                      <strong>Success!</strong> Indicates a
                                      successful or positive action.
                                    </div>
                                  ) : null}

                                  {this.state.addingLoading === true ? (
                                    <ReactLoading type="cylon" color="#000" />
                                  ) : null}

                                  <form onSubmit={this.submitPrimaryEducation}>
                                    <div className="form-group">
                                      <label>School Name *</label>
                                      <input
                                        name="school"
                                        className="form-control"
                                        placeholder="School name"
                                        value={this.state.school}
                                        onChange={this.handleInputState}
                                      />
                                      {this.state.errors.school ? (
                                        <div className="errorMsg">
                                          {this.state.errors.school}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <label>Completion Year *</label>
                                      <select
                                        name="year"
                                        className="selection-box  input-placeholder"
                                        value={this.state.year}
                                        onChange={this.handleInputState}
                                      >
                                        <option key=" ">
                                          {" "}
                                          Selection Completion Year
                                        </option>
                                        {yearList}
                                      </select>
                                      {this.state.errors.year ? (
                                        <div className="errorMsg">
                                          {this.state.errors.year}
                                        </div>
                                      ) : null}
                                    </div>
                                    <Button
                                      type="submit"
                                      variant="primary"
                                      className="btn btn-round  btn-sm verification-button"
                                    >
                                      <i className="fa fa-save fa-sm"></i> Save
                                    </Button>
                                  </form>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.stage < 3 ? (
                                <Alert severity="warning">
                                  <strong>Warning!</strong> Fill the previous
                                  Information
                                </Alert>
                              ) : null}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const PrimaryEducation = withRouter(PrimaryEducationComp);
export default PrimaryEducation;
