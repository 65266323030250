import React, { Component } from "react";
import { FaRegHandPointRight } from "react-icons/fa";

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }
  render() {
    return (
      <>
        <p>
          <b>Instructions</b>
        </p>
        <hr />

        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>1.</span>
            <span style={{ color: "green", fontWeight: "bold" }}>
              Congratulation
            </span>
            , You have been selected to DMI in the programme{" "}
            <span style={{ fontWeight: "bold" }}> "{this.props.program}"</span>{" "}
            in <span style={{ fontWeight: "bold" }}>"{this.props.campus}"</span>{" "}
            Campus,
            {""}
          </li>
        </ul>
      </>
    );
  }
}
export default Results;
