import React, { Component } from "react";
import { WiDirectionUpRight } from "react-icons/wi";
import { withRouter } from "react-router";
import { Modal, Button } from "react-bootstrap";
import Alert from "@mui/material/Alert";
import { FaRegEdit, FaUpload, FaRegSave } from "react-icons/fa";
import ReactLoading from "react-loading";
import yearProvider from "../../../util/yearsProvider";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import changeStage from "../../../util/changeStage";
import textValidator from "../../../util/textValidator";
class AddCollegeComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: "",
      yesIsOpen: false,
      noIsOpen: false,
      redirect: false,
      year: "",
      base64Content: "",
      level: null,
      avn: "",
      selectedFile: null,
      setIsSelected: false,
      isSelected: false,
      loading: "",
      fields: {},
      errors: {},
    };
  }

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
  };

  closeModal = () => {
    this.setState({
      foundationNumber: "",
      year: "",
      yesIsOpen: false,
      noIsOpen: false,
      loading: "",
      avn: "",
      fields: {},
      errors: {},
      network_error: false,
      particular_exists: false,
      base64Content: "",
      selectedFile: "",
      setIsSelected: false,
      isSelected: false,
    });
    window.location.reload();
  };

  openModelForm = (e) => {
    e.preventDefault();
    if (this.validateQuestion()) {
      if (this.state.answer === "1") {
        this.setState({ yesIsOpen: true });
        this.setState({ noIsOpen: false });
      }
      if (this.state.answer === "2") {
        this.setState({ noIsOpen: true });
        this.setState({ yesIsOpen: false });
      }
    }
  };

  changeHandler = (event) => {
    if (event.target.files < 1 || !event.target.validity.valid) {
      return;
    }
    let file = event.target.files[0];
    this.fileToBase64(file, (err, result) => {
      if (result) {
        let data = result.split(",")[1];
        this.setState({
          selectedFile: file,
          setIsSelected: true,
          isSelected: true,
          base64Content: data,
        });
      }
    });

    this.setState({
      selectedFile: event.target.files[0],
      setIsSelected: true,
      isSelected: true,
    });
  };

  fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  validateQuestion = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["answer"]) {
      formIsValid = false;
      errors["answer"] = "* Please select the answer.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };
  validateLevel = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["level"]) {
      formIsValid = false;
      errors["level"] = "* Please select level .";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  validateFoundation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["foundationNumber"]) {
      formIsValid = false;
      errors["foundationNumber"] = "* Please fill the foundation number .";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  validateNVA = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["avn"]) {
      formIsValid = false;
      errors["avn"] = "* Please fill the nva number .";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  validateCertificateBachelor = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["college_name"]) {
      formIsValid = false;
      errors["college_name"] =
        "* Please fill Institution/Collage/University name.";
    }
    if (
      fields["college_name"] &&
      textValidator(fields["college_name"]) === false
    ) {
      formIsValid = false;
      errors["college_name"] =
        "* Institution/Collage/University name must text.";
    }
    if (!fields["program"]) {
      formIsValid = false;
      var coll = fields["college_name"]
        ? "* Please fill the programme you studied at " + fields["college_name"]
        : "Programme is empty";
      errors["program"] = coll;
    }

    if (fields["program"] && textValidator(fields["program"]) === false) {
      formIsValid = false;
      // errors["college_name"] = "* The programme  name must text.";
      errors["program"] = "* The programme  name must text.";
    }

    if (!fields["registrationNumber"]) {
      formIsValid = false;
      var regError = fields["program"]
        ? "* Please fill registration as in your  " +
          fields["program"] +
          " " +
          "Transcript"
        : "* Registration Number is empty";
      errors["registrationNumber"] = regError;
    }
    if (!fields["gpa"]) {
      formIsValid = false;
      errors["gpa"] = "* Please fill the GPA.";
    }

    if (!fields["year"]) {
      formIsValid = false;
      errors["year"] = "* Please select completion year.";
    }
    if (this.state.base64Content === "") {
      formIsValid = false;
      errors["file"] = "* Please browser your programme transcript.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  submitYesCollegeInformation = async (e) => {
    e.preventDefault();
    if (this.validateLevel()) {
      if (this.state.level === "1") {
        if (this.validateFoundation()) {
          await this.submitFoundationParticulars();
        }
      }
      if (this.state.level === "3") {
        if (this.validateNVA()) {
          await this.submitNVAParticulars();
        }
      }
      if (
        this.state.level === "2" ||
        this.state.level === "4" ||
        this.state.level === "5"
      ) {
        if (this.validateCertificateBachelor()) {
          await this.submitCollegeInformation();
        }
      }
    }
  };

  submitFoundationParticulars = async () => {
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      foundationNumber: this.state.foundationNumber,
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };
    await api
      .post(params.SUBMIT_OUT_DATA, payLoad)
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              foundationNumber: "",
              level: "",
              yesIsOpen: false,
              noIsOpen: false,
              loading: false,
              redirect: true,
              answer: "",
              network_error: false,
            });
            this.props.history.push("/education/college");
            window.location.reload();
          } else if (res.data.stsCode === "0001") {
            this.setState({
              particular_exists: true,
              answer: "",
              loading: false,
            });
          } else {
            this.setState({
              network_error: true,
              answer: "",
              loading: false,
            });
          }
          ///
        } else {
          this.setState({
            network_error: true,
            answer: "",
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ network_error: true, answer: "", loading: false });
      });
  };

  submitNVAParticulars = async () => {
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      avn: this.state.avn,
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };
    await api
      .post(params.SUBMIT_NVA_DATA, payLoad)
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              foundationNumber: "",
              level: "",
              yesIsOpen: false,
              noIsOpen: false,
              loading: false,
              redirect: true,
              answer: "",
              avn: "",
              network_error: false,
            });
            this.props.history.push("/education/college");
            window.location.reload();
          } else if (res.data.stsCode === "0001") {
            // this.setState({
            //   particular_exists: true,
            //   avn: "",
            //   answer: "",
            //   loading: false,
            // });
            alert(res.data.stsMessage);
            window.location.reload();
          } else {
            this.setState({
              network_error: true,
              answer: "",
              loading: false,
            });
          }
          ///
        } else {
          this.setState({
            network_error: true,
            answer: "",
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ network_error: true, answer: "", loading: false });
      });
  };

  submitCollegeInformation = async () => {
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      institutionName: this.state.college_name,
      program: this.state.program,
      registrationNumber: this.state.registrationNumber,
      completedYear: this.state.year,
      gpa: this.state.gpa,
      awardId: parseInt(localStorage.getItem("awardId")),
      transcript: this.state.base64Content,
    };
    await api
      .post(params.SUBMIT_COLLEGE_DATA, payLoad)
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              level: "",
              applicationProfileId: "",
              institutionName: "",
              program: "",
              registrationNumber: "",
              completedYear: "",
              gpa: "",
              awardId: "",
              transcript: "",
              yesIsOpen: false,
              noIsOpen: false,
              loading: false,
              redirect: true,
              answer: "",
              avn: "",
              network_error: false,
            });
            this.props.history.push("/education/college");
            window.location.reload();
          } else if (res.data.stsCode === "0001") {
            this.setState({
              particular_exists: true,
              avn: "",
              answer: "",
              loading: false,
            });
          } else {
            this.setState({
              network_error: true,
              answer: "",
              loading: false,
            });
          }
          ///
        } else {
          this.setState({
            network_error: true,
            answer: "",
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ network_error: true, answer: "", loading: false });
      });
  };

  validateNoForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["confirmation_answer"]) {
      formIsValid = false;
      errors["confirmation_answer"] = "* Please select the answer.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  getCollegeEducationNoAnswer = async (e) => {
    e.preventDefault();
    if (this.validateNoForm()) {
      if (this.state.confirmation_answer === "1") {
        let payLoad = {
          applicationProfileId: parseInt(localStorage.getItem("id")),
          stageId: 7,
        };
        let result = await changeStage(payLoad);
        if (result === 1) {
          this.setState({
            noIsOpen: false,
            answer: "",
            olevel_question: "",
            loading: "",
            errors: {},
            yesIsOpen: false,
          });
          this.props.history.push("/applying-programs/applying-programs");
          window.location.reload();
        }
      }
      if (this.state.confirmation_answer === "2") {
        this.setState({
          noIsOpen: false,
          index_number: "",
          year: "",
          answer: "",
          olevel_question: "",
          loading: "",
          errors: {},
          yesIsOpen: false,
        });
      }
    }
  };

  render() {
    let data = this.props.college_data;
    // const blob = this.dataURItoBlob(data);
    // const url = URL.createObjectURL(blob);
    const yearList = yearProvider().map((x) => {
      return (
        <option key={x} value={x}>
          {x}
        </option>
      );
    });
    return (
      <>
        <form onSubmit={this.openModelForm}>
          <div className="form-group">
            <label>
              {data.length > 0 ? (
                <>
                  Do you have another College Education other than (
                  <WiDirectionUpRight size={40} />) ?{" "}
                </>
              ) : (
                <>
                  {" "}
                  Do you have College Educatin (Certificate, Diploma, Bachelor
                  and etc)?
                </>
              )}
              <font className="label-comment">*</font>
            </label>
            <select
              name="answer"
              className="selection-box  input-placeholder"
              value={this.state.answer}
              onChange={this.handleInputState}
            >
              <option key={0} value="">
                {" "}
                -- Select the answer --{" "}
              </option>
              <option key={1} value="1">
                Yes
              </option>
              <option key={2} value="2">
                No
              </option>
            </select>
            {this.state.errors.answer ? (
              <div className="errorMsg">{this.state.errors.answer}</div>
            ) : null}
          </div>
          <div className="form-group">
            <Button
              type="submit"
              variant="primary"
              className="btn btn-round  btn-sm"
            >
              <FaRegSave />
              {"     "} Submit Answer
            </Button>
          </div>
        </form>

        <Modal size="lg" show={this.state.yesIsOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <div className="float-center card-header-title">
              <FaRegEdit />
              Add College Education
            </div>
          </Modal.Header>
          <form onSubmit={this.submitYesCollegeInformation}>
            <Modal.Body>
              {this.state.loading === true ? (
                <>
                  <ReactLoading type="cylon" color="#000" />
                </>
              ) : null}
              {this.state.network_error === true ? (
                <>
                  <Alert onClose={this.closeNetworkWarning} severity="error">
                    You are facing netwok problems — Please try again later or
                    contact the system administrator!
                  </Alert>
                </>
              ) : null}
              <div className="form-group">
                <label>
                  Select level
                  <font className="label-comment">*</font>
                </label>
                <select
                  name="level"
                  className="selection-box  input-placeholder"
                  value={this.state.level}
                  onChange={this.handleInputState}
                >
                  <option key={0} value="">
                    {" "}
                    -- Select the level --{" "}
                  </option>

                  <option key={1} value="1">
                    Foundation
                  </option>
                  <option key={2} value="2">
                    Certificate
                  </option>
                  <option key={3} value="3">
                    Diploma
                  </option>
                  <option key={4} value="4">
                    Bachelor Degree
                  </option>
                  <option key={5} value="5">
                    Proffesional Certificate
                  </option>
                </select>
                {this.state.errors.level ? (
                  <div className="errorMsg">{this.state.errors.level}</div>
                ) : null}
              </div>
              {this.state.level === "1" ? (
                <div className="form-group">
                  <label>
                    Foundation Number (From Open University)
                    <font className="label-comment">*</font>
                  </label>
                  <input
                    type="text"
                    name="foundationNumber"
                    className="form-control input-placeholder"
                    value={this.state.foundationNumber}
                    onChange={this.handleInputState}
                    placeholder="E.g. NXX-XXX-XXXX"
                  ></input>
                  {this.state.errors.foundationNumber ? (
                    <div className="errorMsg">
                      {this.state.errors.foundationNumber}
                    </div>
                  ) : null}
                </div>
              ) : null}
              {this.state.level === "3" ? (
                <div className="form-group">
                  <label>
                    AVN Number (NACTE)
                    <font className="label-comment">*</font>
                  </label>
                  <input
                    type="text"
                    name="avn"
                    className="form-control input-placeholder"
                    value={this.state.avn}
                    onChange={this.handleInputState}
                    placeholder="E.g. XXNAXXXXXXXME"
                  ></input>
                  {this.state.errors.avn ? (
                    <div className="errorMsg">{this.state.errors.avn}</div>
                  ) : null}
                </div>
              ) : null}

              {this.state.level === "2" ||
              this.state.level === "4" ||
              this.state.level === "5" ? (
                <>
                  <div className="form-group">
                    <label>
                      Institution/College Name
                      <font className="label-comment">*</font>
                    </label>
                    <input
                      type="text"
                      name="college_name"
                      className="form-control input-placeholder"
                      value={this.state.college_name}
                      onChange={this.handleInputState}
                      placeholder="College Name"
                    ></input>
                    {this.state.errors.college_name ? (
                      <div className="errorMsg">
                        {this.state.errors.college_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label>
                      Program as appear on your transcript
                      <font className="label-comment">*</font>
                    </label>
                    <input
                      type="text"
                      name="program"
                      className="form-control input-placeholder"
                      value={this.state.program}
                      onChange={this.handleInputState}
                      placeholder="Program"
                    ></input>
                    {this.state.errors.program ? (
                      <div className="errorMsg">
                        {this.state.errors.program}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label>
                      Registration Number
                      <font className="label-comment">*</font>
                    </label>
                    <input
                      type="text"
                      name="registrationNumber"
                      className="form-control input-placeholder"
                      value={this.state.registrationNumber}
                      onChange={this.handleInputState}
                      placeholder="Registration Number"
                    ></input>
                    {this.state.errors.registrationNumber ? (
                      <div className="errorMsg">
                        {this.state.errors.registrationNumber}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label>
                      GPA
                      <font className="label-comment">*</font>
                    </label>
                    <input
                      type="number"
                      name="gpa"
                      className="form-control input-placeholder"
                      value={this.state.gpa}
                      onChange={this.handleInputState}
                      placeholder="GPA"
                    ></input>
                    {this.state.errors.gpa ? (
                      <div className="errorMsg">{this.state.errors.gpa}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label>
                      Year completed
                      <font className="label-comment">*</font>
                    </label>
                    <select
                      name="year"
                      className="selection-box  input-placeholder"
                      value={this.state.year}
                      onChange={this.handleInputState}
                    >
                      <option key=""> Selection Completion Year</option>
                      {yearList}
                    </select>
                    {this.state.errors.year ? (
                      <div className="errorMsg">{this.state.errors.year}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label>
                      Upload Transcript (The file must be in pdf )
                      <font className="label-comment">*</font>
                    </label>
                    <input
                      type="file"
                      name="file"
                      className="form-control input-placeholder"
                      onChange={this.changeHandler}
                      accept="application/pdf"
                    />
                    {this.state.isSelected === true ? (
                      <div>
                        <p>Filename: {this.state.selectedFile.name}</p>
                      </div>
                    ) : (
                      <p>Select a file to show details</p>
                    )}
                    {this.state.errors.file ? (
                      <div className="errorMsg">{this.state.errors.file}</div>
                    ) : null}
                  </div>
                </>
              ) : null}
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                className="btn btn-round  btn-sm"
              >
                <FaUpload />
                {"     "}Save
              </Button>
              <Button
                variant="secondary"
                className="btn btn-round  btn-sm btn btn-round"
                onClick={this.closeModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal size="lg" show={this.state.noIsOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <div className="float-center card-header-title">
              <FaRegEdit />
              Tertiary/College Education
            </div>
          </Modal.Header>

          <form onSubmit={this.getCollegeEducationNoAnswer}>
            <Modal.Body>
              <div className="form-group">
                <label className="label-class">
                  {data.length > 0 ? (
                    <>
                      Are sure have not attended any other college education
                      other than the one you have mentioned?{" "}
                    </>
                  ) : (
                    <>
                      Are sure have not attended any other college education
                      other than the one you have mentioned?
                    </>
                  )}
                  <font className="label-comment">*</font>
                </label>
                <select
                  name="confirmation_answer"
                  className="selection-box  input-placeholder"
                  value={this.state.confirmation_answer}
                  onChange={this.handleInputState}
                >
                  <option key="0" value="">
                    {" "}
                    -- Selection Answer --
                  </option>
                  <option key="1" value="1">
                    {" "}
                    Yes
                  </option>
                  <option key="2" value="2">
                    {" "}
                    No
                  </option>
                </select>
                {this.state.errors.confirmation_answer ? (
                  <div className="errorMsg">
                    {this.state.errors.confirmation_answer}
                  </div>
                ) : null}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                variant="primary"
                className="btn btn-round  btn-sm"
              >
                <FaRegSave />
                {"     "} Submit Answer
              </Button>
              <Button
                variant="secondary"
                className="btn btn-round  btn-sm btn btn-round"
                onClick={this.closeModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}
const AddCollege = withRouter(AddCollegeComp);
export default AddCollege;
