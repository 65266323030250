import React, { Component } from "react";
import { FaRegHandPointDown, FaRegHandPointRight } from "react-icons/fa";
class HighSchoolInstruction extends Component {
  render() {
    return (
      <>
        {this.props.filling_arrow === "right" ? (
          <>
            <p>
              <b>Instructions</b>
            </p>
            <hr />

            <ul>
              Please fill your Advanced Secondary Education through answering
              different questions which will be prompted to you.{" "}
              <FaRegHandPointRight size={25} />
              <br></br>
              <li>
                If you have not attended any Advanced Secondary school,please
                select No and confirm
              </li>
              <li style={{ paddingTop: "5px" }}>
                <b>Note</b>. The fields with * are mandatory
              </li>
            </ul>
          </>
        ) : (
          <>
            <p>
              <b>Instructions</b>
            </p>
            <hr />

            <ul>
              Please fill your Advanced Secondary Education through answering
              different questions which will be prompted to you{" "}
              <li>
                If you have not attended any Advanced Secondary school,please
                select No and confirm
              </li>
              <li style={{ paddingTop: "5px" }}>
                <b>Note</b>. The fields with * are mandatory
              </li>
              <br></br>
              <li>
                The form can found below <FaRegHandPointDown size={25} />
              </li>
              <br></br>
            </ul>
          </>
        )}
      </>
    );
  }
}
export default HighSchoolInstruction;
