import React, { Component } from "react";

class EditPrimaryEducation extends Component {
  state = {
    school_name: this.props.schoolname,
    year: this.props.completed_year,
  };

  handleInputState = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    let allYears = [];
    let thisYear = new Date().getFullYear();

    //console.log(thisYear - 10);
    let start = thisYear - 4; // initiate year
    for (let x = start; x > 1970; x--) {
      allYears.push(x);
    }

    const yearList = allYears.map((x) => {
      return (
        <option key={x} value={x}>
          {x}
        </option>
      );
    });
    return (
      <React.Fragment>
        <div className="form-group">
          <label>School Name </label>
          <input
            name="school_name"
            className="form-control"
            value={this.state.school_name}
            onChange={this.handleInputState}
          />
        </div>

        <select
          name="year"
          className="selection-box  input-placeholder"
          value={this.state.name}
          onChange={this.handleInputState}
        ></select>

        <div className="form-group">
          <select
            name="year"
            className="selection-box  input-placeholder"
            value={this.state.name}
            onChange={this.handleInputState}
          >
            <option key=""> Selection Completion Year</option>
            {yearList}
          </select>
        </div>
      </React.Fragment>
    );
  }
}
export default EditPrimaryEducation;
