import React from "react";
import yearProvider from "../../util/yearsProvider";
import indexNumberValidator from "../../util/indexNumberValidator";
import emailManipulation from "../../util/emailManipulation";
import randomPasswordGenerator from "../../util/randomPasswordGenerator";
import hashPasscode from "../../util/hashPasscode";
import ReactLoading from "react-loading";
import { withRouter } from "react-router";
import { FaRegHandPointDown } from "react-icons/fa";
class ResetAccountCom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "",
      index_number: "",
      data: "",
      loading: "",
      fields: {},
      errors: {},
      sendEmail: "",
      sendPhone: "",
      network_error: false,
    };
  }
  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };
  handCheckExistence = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true, network_error: false });
      let payLoad = {
        index_number: this.state.index_number,
        year: this.state.year,
        email: "emmanuel.kuwilwa@eastc.ac.tz",
      };
      this.setState({
        sendEmail: emailManipulation(payLoad.email),
        loading: false,
      });
    }
  };

  confirmationForm = (e) => {
    e.preventDefault();
    let passcode = randomPasswordGenerator();
    var payLoad = {
      email: this.state.sendEmail,
      password: hashPasscode(passcode),
      // content: `<div>
      // <h3>TANZANIA INSTITUTE OF ACCOUNTANCY ADMISSION SYSTEM</h3>
      // <p>New Password : <b>${passcode}</b></b>
      // <p>Please use the password to login to you application account and  reset after first login</p>
      // </div>`,
      content: `${passcode}`,
    };
    alert(payLoad.content);
  };

  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["index_number"]) {
      formIsValid = false;
      errors["index_number"] = "* Please fill the  a level index number.";
    }
    if (
      fields["index_number"] &&
      indexNumberValidator(fields["index_number"]) === false
    ) {
      formIsValid = false;
      errors["index_number"] = "* Wrong index number format.";
    }
    if (!fields["year"]) {
      formIsValid = false;
      errors["year"] = "* Please select completion year.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };
  render() {
    const yearList = yearProvider().map((x) => {
      return (
        <option key={x} value={x}>
          {x}
        </option>
      );
    });
    return (
      <div className="row">
        <div className="col-sm-1"> &nbsp;</div>
        <div className="col-sm-10">
          <div className="card">
            <div className="card-header">
              <div className="float-left card-header-title">
                Forgot password ?
              </div>
              <div className="float-right">
                <button
                  className="backbutton"
                  onClick={() => this.props.history.push("/")}
                >
                  {" "}
                  <i className="ti-angle-left"></i>Back
                </button>
              </div>
            </div>
            <div className="card-block">
              <div className="block">
                <div className="resetpasswordcontent">
                  {this.state.loading === true ? (
                    <>
                      <ReactLoading type="cylon" color="#000" />
                    </>
                  ) : null}
                  {this.state.sendEmail === "" ? (
                    <>
                      <p>
                        If you need to reset your password, please enter your
                        four form index number you used to create the
                        application below <i className="ti-hand-point-down"></i>
                      </p>
                      <form onSubmit={this.handCheckExistence}>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="label-class">
                                O Level Index Number
                                <font className="label-comment">*</font>
                              </label>
                              <input
                                type="text"
                                name="index_number"
                                className="form-control input-placeholder"
                                value={this.state.index_number}
                                onChange={this.handleInputState}
                                placeholder="E.g. SXXXX-XXXX  or EQXXXXXXXXXXX"
                              ></input>
                              {this.state.errors.index_number ? (
                                <div className="errorMsg">
                                  {this.state.errors.index_number}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <label className="label-class">
                                Completion Year
                                <font className="label-comment">*</font>
                              </label>
                              <select
                                name="year"
                                className="selection-box  input-placeholder"
                                value={this.state.year}
                                onChange={this.handleInputState}
                              >
                                <option key="">
                                  {" "}
                                  Selection Completion Year
                                </option>
                                {yearList}
                              </select>
                              {this.state.errors.year ? (
                                <div className="errorMsg">
                                  {this.state.errors.year}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <label className="label-class">
                                {" "}
                                <br></br>
                                <br></br>
                              </label>

                              <button
                                type="submit"
                                className="btn btn-round  btn-md btn-primary btn-md"
                                style={{ marginTop: "28px" }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-sm-1"></div>
                        <p>
                          Do you confirm your new password to be send to the
                          email with the following characteristics below{" "}
                          <FaRegHandPointDown size={25} />
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-sm-1"></div>
                        <p>
                          <font style={{ color: "green", fontStyle: "italic" }}>
                            {this.state.sendEmail}
                          </font>
                        </p>
                      </div>

                      <form onSubmit={this.confirmationForm}>
                        <div className="row">
                          <div className="col-sm-1"></div>
                          <div className="col-sm-1">
                            <div className="form-group">
                              <input type="radio" value="1" name="decision" />{" "}
                              Yes
                            </div>
                          </div>
                          <div className="col-sm-1">
                            <div className="form-group">
                              <input type="radio" value="2" name="decision" />{" "}
                              No
                            </div>
                          </div>
                          <div className="col-sm-1">
                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn btn-round  btn-sm btn-primary btn-md"
                              >
                                Send Confirmation
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-1"> &nbsp;</div>
      </div>
    );
  }
}
const ResetAccount = withRouter(ResetAccountCom);
export default ResetAccount;
