import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router";
import changeStage from "../../util/changeStage";
import ReactLoading from "react-loading";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Alert from "@mui/material/Alert";
import params from "../../util/api_routes/params";
import { FaHandPointRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import api from "../../util/api";
import Accordion from "react-bootstrap/Accordion";

class ApplyingProgramDisplayComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programs_data: [],
      stage: parseInt(localStorage.getItem("stageid")),
      loading: null,
      network_error: false,
    };
  }

  changePrograme = async (e) => {
    e.preventDefault();
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };

    await api
      .post(params.CHANGE_PROGRAMME, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              new_stage: localStorage.setItem("stageid", 7),
              submit_application: res.data.data,
              loading: false,
              network_error: false,
            });

            window.location.reload();
            // this.props.history.push({
            //   state: {
            //     submit_application_data: this.state.submit_application,
            //   },
            //   pathname: "/applying-programs/applying-programs",
            // });
          } else {
            this.setState({
              network_error: true,
              loading: false,
            });
            console.log(parseInt(localStorage.getItem("stageid")));
          }
        }
      })
      .catch((err) => {
        this.setState({ network_error: true, loading: false });
        console.log(parseInt(localStorage.getItem("stageid")));
      });
  };

  nextToConfirmationSubmission = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 8,
    };
    console.log(parseInt(localStorage.getItem("stageid")));
    let result = await changeStage(payLoad);
    if (result === 1) {
      this.setState({ loading: false, network_error: false });
      this.props.history.push("/application/declaration");
    } else {
    }
  };
  render() {
    let count = 1;
    return (
      <>
        {this.props.programs_data.length > 0 ? (
          <>
            {this.state.loading === true ? (
              <>
                <div className="row">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-2">
                    <ReactLoading type="cylon" color="#000" />
                  </div>
                  <div className="col-sm-6"></div>
                </div>
              </>
            ) : (
              <>
                {this.state.loading === true ? (
                  <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                      <div className="alert alert-warning">
                        <strong>Warning!</strong> You are facing a connection
                        problem, please contact the system administrator OR try
                        later
                      </div>
                    </div>
                    <div className="col-sm-1"></div>
                  </div>
                ) : null}
              </>
            )}
            {/* Display Applying programmes */}
            <table className="table table-bordered">
              {/* <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#f1f5f7",
                      fontWeight: "bold",
                      fontFamily: "Helvetica",
                      fontSize: "13px",
                    }}
                    colSpan="7"
                  >
                    APPLIED PROGRAMMES
                  </th>
                </tr>
              </thead> */}

              <tbody>
                {/* <div style={{ paddingTop: "40px" }}> */}
                <Accordion defaultActiveKey="0">
                  {this.props.programs_data.map((option, i) => (
                    <div key={i}>
                      {i === 0 ? (
                        <Accordion.Item eventKey="0" key={i}>
                          <Accordion.Header
                            className="accordinHeader"
                            key={i + "accordinHeader"}
                          >
                            APPLIED PROGRAMMES
                          </Accordion.Header>
                          <Accordion.Body
                            className="accordionBody"
                            key={i + "accordionBody"}
                          >
                            <table
                              className="table table-bordered table-striped"
                              key={i + "table"}
                            >
                              <tbody key={i + "tablebody"}>
                                <tr key={i + "tableHeader"}>
                                  <td key={0 + i}>PROGRAM</td>
                                  <td key={1 + i}>CAMPUS</td>
                                  <td key={2 + i}>CHOICE</td>
                                </tr>

                                {this.props.programs_data.map((opt, j) => (
                                  <tr key={j + i}>
                                    <td key={j + i + 0}>{opt.program}</td>
                                    <td key={j + i + 1}>{opt.campus}</td>
                                    <td key={j + i + 2}>{opt.choice}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Accordion.Body>
                        </Accordion.Item>
                      ) : (
                        <>
                          {/* <Accordion.Item
                                    eventKey={i}
                                    key={i + "Accordion"}
                                  >
                                    <Accordion.Header
                                      className="accordinHeader"
                                      key={i + "accordinHeader"}
                                    >
                                      PROGRAMMES
                                    </Accordion.Header>
                                    <Accordion.Body
                                      className="accordionBody"
                                      key={i + "accordionBody"}
                                    >
                                      <table
                                        className="table table-bordered table-striped"
                                        key={i + "table"}
                                      >
                                        <tbody key={i + "tablebody"}>
                                          <tr key={i + "tableHeader"}>
                                            <td key={0 + i}>PROGRAM</td>
                                            <td key={1 + i}>CAMPUS</td>
                                            <td key={2 + i}>CHOICE</td>
                                          </tr>

                                          {this.props.programs_data.map(
                                            (opt, j) => (
                                              <tr key={j + i}>
                                                <td key={j + i + 0}>
                                                  {opt.program}
                                                </td>
                                                <td key={j + i + 1}>
                                                  {opt.campus}
                                                </td>
                                                <td key={j + i + 2}>
                                                  {opt.choice}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </Accordion.Body>
                                  </Accordion.Item> */}
                        </>
                      )}
                    </div>
                  ))}
                </Accordion>
                {/* </div> */}
              </tbody>
            </table>
            {/* Display Applying programmes */}
            {/* Edit Programmes */}
            {this.state.stage === 7 ? (
              <>
                <div
                  style={{
                    color: "blue",
                    fontSize: "17px",
                  }}
                >
                  {/* <Link
                  onClick={this.changePrograme}
                  style={{
                    color: "red",
                    fontSize: "17px",
                  }}
                > */}
                  <ul>
                    <li>
                      {" "}
                      <FaHandPointRight />
                      Click{" "}
                      <Link>
                        <span
                          style={{
                            color: "green",
                            fontSize: "18px",
                          }}
                          onClick={this.changePrograme}
                        >
                          Here
                        </span>{" "}
                      </Link>
                      to edit programmes.
                    </li>
                  </ul>
                  {/* </Link> */}
                </div>
              </>
            ) : null}
            {/* Edit Programmes */}
            {this.state.stage === 7 ? (
              <>
                <Row>
                  <Col xs="auto" style={{ paddingTop: "25px" }}>
                    <Button
                      type="submit"
                      className="btn btn-round  btn-md"
                      variant="primary"
                      onClick={this.nextToConfirmationSubmission.bind(this)}
                    >
                      Continue to Declaration and Submission{" "}
                      <FaArrowAltCircleRight size={20} />
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                {this.state.stage > 7 ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <>
                      <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                          <Alert severity="warning">
                            Please fill the previous stages
                          </Alert>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                    </>
                  </>
                )}
              </>
            )}
          </>
        ) : null}
      </>
    );
  }
}
const ApplyingProgramDisplay = withRouter(ApplyingProgramDisplayComp);
export default ApplyingProgramDisplay;
