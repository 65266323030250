import React, { Component } from "react";
import { WiDirectionUpRight } from "react-icons/wi";
import { withRouter } from "react-router";
import { Modal, Button } from "react-bootstrap";
import Alert from "@mui/material/Alert";
import { FaRegEdit, FaUpload, FaRegSave } from "react-icons/fa";
import ReactLoading from "react-loading";
import yearProvider from "../../../util/yearsProvider";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import changeStage from "../../../util/changeStage";
import textValidator from "../../../util/textValidator";
class AddReferenceComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: "",
      yesIsOpen: false,
      noIsOpen: false,
      redirect: false,
      year: "",
      base64Content: "",
      level: null,
      avn: "",
      selectedFile: null,
      setIsSelected: false,
      isSelected: false,
      loading: "",
      fields: {},
      errors: {},
      permit_type: "",
      permit_no: "",
    };
  }

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
  };

  closeModal = () => {
    this.setState({
      foundationNumber: "",
      year: "",
      yesIsOpen: false,
      noIsOpen: false,
      loading: "",
      avn: "",
      fields: {},
      errors: {},
      network_error: false,
      particular_exists: false,
      base64Content: "",
      selectedFile: "",
      setIsSelected: false,
      isSelected: false,
    });
    window.location.reload();
  };

  openModelForm = (e) => {
    e.preventDefault();
    if (this.validateQuestion()) {
      if (this.state.answer === "1") {
        this.setState({ yesIsOpen: true });
        this.setState({ noIsOpen: false });
      }
      if (this.state.answer === "2") {
        this.setState({ noIsOpen: true });
        this.setState({ yesIsOpen: false });
      }
    }
  };

  changeHandler = (event) => {
    if (event.target.files < 1 || !event.target.validity.valid) {
      return;
    }
    let file = event.target.files[0];
    this.fileToBase64(file, (err, result) => {
      if (result) {
        let data = result.split(",")[1];
        this.setState({
          selectedFile: file,
          setIsSelected: true,
          isSelected: true,
          base64Content: data,
        });
      }
    });

    this.setState({
      selectedFile: event.target.files[0],
      setIsSelected: true,
      isSelected: true,
    });
  };

  fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  validateQuestion = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["answer"]) {
      formIsValid = false;
      errors["answer"] = "* Please select the answer.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };
  validateLevel = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["permit_type"]) {
      formIsValid = false;
      errors["permit_type"] =
        "* Please select type (Study Permit or Passport) .";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  validateReference = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["permit_no"]) {
      formIsValid = false;
      errors["permit_no"] =
        "* Please fill in Permit Number or Passport Number.";
    }
    if (this.state.base64Content === "") {
      formIsValid = false;
      errors["file"] = "* Please browser your Study Permit or Passport.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  submitYesReferenceInformation = async (e) => {
    e.preventDefault();
    if (this.validateLevel()) {
      // if (this.state.level === "2" || this.state.level === "4") {
      if (this.validateReference()) {
        await this.submitReferenceInformation();
      }
      // }
    }
  };

  submitReferenceInformation = async () => {
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      permitType: this.state.permit_type,
      permitNo: this.state.permit_no,
      attachment: this.state.base64Content,
    };
    await api
      .post(params.SUBMIT_REFERENCE_DATA, payLoad)
      .then((res) => {
        console.log(res.status);
        if (res.status === 200) {
          // if (res.data.stsCode === "0000") {
          this.setState({
            yesIsOpen: false,
            noIsOpen: false,
            loading: false,
            redirect: true,
            answer: "",
            network_error: false,
          });
          this.props.history.push("/reference/reference");
          window.location.reload();
          // } else if (res.data.stsCode === "0001") {
          // this.setState({
          //   particular_exists: true,
          //   answer: "",
          //   loading: false,
          // });
          // } else {
          // this.setState({
          //   network_error: true,
          //   answer: "",
          //   loading: false,
          // });
          // }
          ///
        } else {
          alert("failed to upload: Duplicate Entry");
          // this.setState({
          //   particular_exists: true,
          //   network_error: true,
          //   answer: "",
          //   loading: false,
          // });
        }
      })
      .catch((err) => {
        alert("failed to upload: Duplicate Entry");
        window.location.reload();
        this.setState({ network_error: true, answer: "", loading: false });
      });
  };

  validateNoForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["confirmation_answer"]) {
      formIsValid = false;
      errors["confirmation_answer"] = "* Please select the answer.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  getReferenceNoAnswer = async (e) => {
    e.preventDefault();
    if (this.validateNoForm()) {
      if (this.state.confirmation_answer === "1") {
        // let payLoad = {
        //   applicationProfileId: parseInt(localStorage.getItem("id")),
        //   stageId: 3,
        // }; last comment

        let finalPayload;

        if (parseInt(localStorage.getItem("awardId")) == 6) {
          finalPayload = {
            applicationProfileId: parseInt(localStorage.getItem("id")),
            stageId: 8,
          };
        } else {
          finalPayload = {
            applicationProfileId: parseInt(localStorage.getItem("id")),
            stageId: 3,
          };
        }
        const payLoad = finalPayload;
        let result = await changeStage(payLoad);
        if (result === 1) {
          this.setState({
            noIsOpen: false,
            answer: "",
            olevel_question: "",
            loading: "",
            errors: {},
            yesIsOpen: false,
          });
          console.log("declaration");
          // this.props.history.push("/applying-programs/applying-programs"); old comment
          if (parseInt(localStorage.getItem("awardId")) == 6) {
            this.props.history.push("/application/declaration");
          } else {
            this.props.history.push("/education/primary");
          }
          // this.props.history.push("/education/primary");   last comment
          window.location.reload();
        }
      }
      if (this.state.confirmation_answer === "2") {
        this.setState({
          noIsOpen: false,
          index_number: "",
          year: "",
          answer: "",
          olevel_question: "",
          loading: "",
          errors: {},
          yesIsOpen: false,
        });
      }
    }
  };

  render() {
    let data = this.props.reference_data;
    // const blob = this.dataURItoBlob(data);
    // const url = URL.createObjectURL(blob);
    const yearList = yearProvider().map((x) => {
      return (
        <option key={x} value={x}>
          {x}
        </option>
      );
    });
    return (
      <>
        <form onSubmit={this.openModelForm}>
          <div className="form-group">
            <label>
              {data.length > 0 ? (
                <>
                  Upload Study Permit or Passport (
                  <WiDirectionUpRight size={40} />) ?{" "}
                </>
              ) : (
                <> Do you have Study Permit or Passport ?</>
              )}
              <font className="label-comment">*</font>
            </label>
            <select
              name="answer"
              className="selection-box  input-placeholder"
              value={this.state.answer}
              onChange={this.handleInputState}
            >
              <option key={0} value="">
                {" "}
                -- Select the answer --{" "}
              </option>
              <option key={1} value="1">
                Yes
              </option>
              <option key={2} value="2">
                No
              </option>
            </select>
            {this.state.errors.answer ? (
              <div className="errorMsg">{this.state.errors.answer}</div>
            ) : null}
          </div>
          <div className="form-group">
            <Button
              type="submit"
              variant="primary"
              className="btn btn-round  btn-sm"
            >
              <FaRegSave />
              {"     "} Submit Answer
            </Button>
          </div>
        </form>

        <Modal size="lg" show={this.state.yesIsOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <div className="float-center card-header-title">
              <FaRegEdit />
              Study Permit or Passport
            </div>
          </Modal.Header>
          <form onSubmit={this.submitYesReferenceInformation}>
            <Modal.Body>
              {this.state.loading === true ? (
                <>
                  <ReactLoading type="cylon" color="#000" />
                </>
              ) : null}
              {this.state.network_error === true ? (
                <>
                  <Alert onClose={this.closeNetworkWarning} severity="error">
                    You are facing netwok problems — Please try again later or
                    contact the system administrator!
                  </Alert>
                </>
              ) : null}
              <div className="form-group">
                <label>
                  Select type
                  <font className="label-comment">*</font>
                </label>
                <select
                  name="permit_type"
                  className="selection-box  input-placeholder"
                  value={this.state.permit_type}
                  onChange={this.handleInputState}
                >
                  <option key={0} value="">
                    {" "}
                    -- Select the type --{" "}
                  </option>

                  <option key={2} value="StudyPermit">
                    Study Permit
                  </option>
                  <option key={4} value="Passport">
                    Passport
                  </option>
                </select>
                {this.state.errors.permit_type ? (
                  <div className="errorMsg">
                    {this.state.errors.permit_type}
                  </div>
                ) : null}
              </div>

              {/* {this.state.level === "2" || this.state.level === "4" ? (
                <> */}
              <div className="form-group">
                <label>
                  Study Permit Number / Passport Number
                  <font className="label-comment">*</font>
                </label>
                <input
                  type="text"
                  name="permit_no"
                  className="form-control input-placeholder"
                  value={this.state.permit_no}
                  onChange={this.handleInputState}
                  placeholder="Study Permit Number / Passport Number"
                ></input>
                {this.state.errors.permit_no ? (
                  <div className="errorMsg">{this.state.errors.permit_no}</div>
                ) : null}
              </div>

              <div className="form-group">
                <label>
                  Upload Study Permit / Passport (The file must be in pdf
                  (Maximum size 1MB) )<font className="label-comment">*</font>
                </label>
                <input
                  type="file"
                  name="file"
                  className="form-control input-placeholder"
                  onChange={this.changeHandler}
                  accept="application/pdf"
                />
                {this.state.isSelected === true ? (
                  <div>
                    <p>Filename: {this.state.selectedFile.name}</p>
                  </div>
                ) : (
                  <p>Select a file to show details</p>
                )}
                {this.state.errors.file ? (
                  <div className="errorMsg">{this.state.errors.file}</div>
                ) : null}
              </div>
              {/* </>
              ) : null} */}
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                className="btn btn-round  btn-sm"
              >
                <FaUpload />
                {"     "}Save
              </Button>
              <Button
                variant="secondary"
                className="btn btn-round  btn-sm btn btn-round"
                onClick={this.closeModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal size="lg" show={this.state.noIsOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <div className="float-center card-header-title">
              <FaRegEdit />
              Study Permit/Passport
            </div>
          </Modal.Header>

          <form onSubmit={this.getReferenceNoAnswer}>
            <Modal.Body>
              <div className="form-group">
                <label className="label-class">
                  {data.length > 0 ? (
                    <>Are you sure do not have Study Permit or Passport ? </>
                  ) : (
                    <>Are you sure do not have Study Permit or Passport ?</>
                  )}
                  <font className="label-comment">*</font>
                </label>
                <select
                  name="confirmation_answer"
                  className="selection-box  input-placeholder"
                  value={this.state.confirmation_answer}
                  onChange={this.handleInputState}
                >
                  <option key="0" value="">
                    {" "}
                    -- Selection Answer --
                  </option>
                  <option key="1" value="1">
                    {" "}
                    Yes
                  </option>
                  <option key="2" value="2">
                    {" "}
                    No
                  </option>
                </select>
                {this.state.errors.confirmation_answer ? (
                  <div className="errorMsg">
                    {this.state.errors.confirmation_answer}
                  </div>
                ) : null}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                variant="primary"
                className="btn btn-round  btn-sm"
              >
                <FaRegSave />
                {"     "} Submit Answer
              </Button>
              <Button
                variant="secondary"
                className="btn btn-round  btn-sm btn btn-round"
                onClick={this.closeModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}
const AddReference = withRouter(AddReferenceComp);
export default AddReference;
