import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

import params from "../../util/api_routes/params";
import api from "../../util/api";
import changeStage from "../../util/changeStage";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");
    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  submitDeclaration = async (e) => {
    e.preventDefault();
    // console.log(`checked: ${this.state.declaration}`);

    // if (this.state.declaration) {
    // msg = "Checked";
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };

    await api
      .post(params.SUBMIT_APPLICATION, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              submit_application: res.data.data,
              loading: false,
              network_error: false,
            });

            // console.log(this.state.submit_application.isQualified);
            // window.location.reload();
            this.props.history.push({
              state: {
                submit_application_data: this.state.submit_application,
              },
              pathname: "/after/submission",
            });
          } else {
            this.setState({
              network_error: true,
              loading: false,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ network_error: true, loading: false });
      });

    // }
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title"></li>
            <li>
              {/* previously the condition was === 10 */}
              {parseInt(localStorage.getItem("stageid")) >= 10 ? (
                <>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="fa fa-id-card" aria-hidden="true"></i>
                    <span className="ml-1">
                      {this.props.t("Personal Details")}
                    </span>
                    {/* {parseInt(localStorage.getItem("stageid")) === 10 ? (
                  <span className="ok">
                    {" "}
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                ) : null} */}
                  </Link>
                  <Link to="/after/finalselection" className="waves-effect">
                    <i className="fa fa-id-card" aria-hidden="true"></i>
                    <span className="ml-1">{this.props.t("Selection")}</span>
                    {/* {parseInt(localStorage.getItem("stageid")) === 10 ? (
                  <span className="ok">
                    {" "}
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                ) : null} */}
                  </Link>
                </>
              ) : (
                <>
                  {/* check if it short is course */}
                  {parseInt(localStorage.getItem("awardId")) == 6 ? (
                    <>
                      <Link to="/dashboard" className="waves-effect">
                        <i className="fa fa-id-card" aria-hidden="true"></i>
                        <span className="ml-1">
                          {this.props.t("Personal Details")}
                        </span>
                        {parseInt(localStorage.getItem("stageid")) > 1 ? (
                          <span className="ok">
                            {" "}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : null}
                      </Link>
                      <Link to="/personal/nextofkin" className="waves-effect">
                        <i
                          className="fas fa-people-arrows"
                          aria-hidden="true"
                        ></i>
                        <span className="ml-1">
                          {this.props.t("Next of Kin")}
                        </span>
                        {parseInt(localStorage.getItem("stageid")) > 2 ? (
                          <span className="ok">
                            {" "}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : null}
                      </Link>
                      <Link to="/personal/reference" className="waves-effect">
                        <i className="fab fa-accusoft" aria-hidden="true"></i>
                        <span className="ml-1">
                          {this.props.t("References")}
                        </span>
                        {parseInt(localStorage.getItem("stageid")) > 2 ? (
                          <span className="ok">
                            {" "}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : null}
                      </Link>
                      {parseInt(localStorage.getItem("stageid")) === 9 ? (
                        <>
                          <Link
                            to="/after/submission"
                            className="waves-effect"
                            onClick={this.submitDeclaration}
                          >
                            <i
                              className="fa fa-thumbs-up"
                              aria-hidden="true"
                            ></i>
                            <span className="ml-1">
                              {this.props.t("Completed ")}
                            </span>
                            {parseInt(localStorage.getItem("stageid")) === 9 ? (
                              <span className="ok">
                                {" "}
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : null}
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link
                            to="/application/declaration"
                            className="waves-effect"
                          >
                            <i
                              className="fa fa-bullhorn"
                              aria-hidden="true"
                            ></i>
                            <span className="ml-1">
                              {this.props.t("Declaration ")}
                            </span>
                            {/* {parseInt(localStorage.getItem("stageid")) === 7 ? (
                              <span className="ok">
                               {" "}
                              <i className="fa fa-check" aria-hidden="true"></i>
                              </span>
                            ) : null} */}
                          </Link>
                        </>
                      )}
                      {/* End check if it short is course */}
                    </>
                  ) : (
                    <>
                      {/* Long course */}
                      <Link to="/dashboard" className="waves-effect">
                        <i className="fa fa-id-card" aria-hidden="true"></i>
                        <span className="ml-1">
                          {this.props.t("Personal Details")}
                        </span>
                        {parseInt(localStorage.getItem("stageid")) > 1 ? (
                          <span className="ok">
                            {" "}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : null}
                      </Link>
                      <Link to="/personal/nextofkin" className="waves-effect">
                        <i
                          className="fas fa-people-arrows"
                          aria-hidden="true"
                        ></i>
                        <span className="ml-1">
                          {this.props.t("Next of Kin")}
                        </span>
                        {parseInt(localStorage.getItem("stageid")) > 2 ? (
                          <span className="ok">
                            {" "}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : null}
                      </Link>
                      <Link to="/personal/reference" className="waves-effect">
                        <i className="fab fa-accusoft" aria-hidden="true"></i>
                        <span className="ml-1">
                          {this.props.t("References")}
                        </span>
                        {parseInt(localStorage.getItem("stageid")) > 2 ? (
                          <span className="ok">
                            {" "}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : null}
                      </Link>
                      <Link to="/education/primary" className="waves-effect">
                        <i className="ri-community-line"></i>
                        <span className="ml-1">
                          {this.props.t("Primary Education")}
                        </span>
                        {parseInt(localStorage.getItem("stageid")) > 3 ? (
                          <span className="ok">
                            {" "}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : null}
                      </Link>{" "}
                      <Link
                        to="/education/secondary-school"
                        className="waves-effect"
                      >
                        <i className="fa fa-book" aria-hidden="true"></i>
                        <span className="ml-1">
                          {this.props.t("Olevel Education")}
                        </span>
                        {parseInt(localStorage.getItem("stageid")) > 4 ? (
                          <span className="ok">
                            {" "}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : null}
                      </Link>
                      <Link
                        to="/education/high-school"
                        className="waves-effect"
                      >
                        <i className="fa fa-book" aria-hidden="true"></i>
                        <span className="ml-1">
                          {this.props.t("High Education")}
                        </span>
                        {parseInt(localStorage.getItem("stageid")) > 5 ? (
                          <span className="ok">
                            {" "}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : null}
                      </Link>
                      <Link to="/education/college" className="waves-effect">
                        <i
                          className="fa fa-graduation-cap"
                          aria-hidden="true"
                        ></i>
                        <span className="ml-1">
                          {this.props.t("College  Education ")}
                        </span>
                        {parseInt(localStorage.getItem("stageid")) > 6 ? (
                          <span className="ok">
                            {" "}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : null}
                      </Link>
                      <Link
                        to="/applying-programs/applying-programs"
                        className="waves-effect"
                      >
                        <i className="fa fa-edit" aria-hidden="true"></i>
                        <span className="ml-1">
                          {this.props.t("Applying Programs ")}
                        </span>
                        {parseInt(localStorage.getItem("stageid")) > 7 ? (
                          <span className="ok">
                            {" "}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : null}
                      </Link>
                      {parseInt(localStorage.getItem("stageid")) === 9 ? (
                        <>
                          <Link
                            to="/after/submission"
                            className="waves-effect"
                            onClick={this.submitDeclaration}
                          >
                            <i
                              className="fa fa-thumbs-up"
                              aria-hidden="true"
                            ></i>
                            <span className="ml-1">
                              {this.props.t("Completed ")}
                            </span>
                            {parseInt(localStorage.getItem("stageid")) === 9 ? (
                              <span className="ok">
                                {" "}
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : null}
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link
                            to="/application/declaration"
                            className="waves-effect"
                          >
                            <i
                              className="fa fa-bullhorn"
                              aria-hidden="true"
                            ></i>
                            <span className="ml-1">
                              {this.props.t("Declaration ")}
                            </span>
                            {/* {parseInt(localStorage.getItem("stageid")) === 7 ? (
                            <span className="ok">
                             {" "}
                             <i className="fa fa-check" aria-hidden="true"></i>
                             </span>
                             ) : null} */}
                          </Link>
                          {/* End Long course */}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
