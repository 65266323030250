import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
class DisplayCollege extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }
  render() {
    return (
      <div style={{ paddingTop: "40px" }}>
        <Accordion defaultActiveKey="0">
          {this.state.stage === 6 ? (
            <>
              {this.props.college_data.map((option, i) => (
                <div key={i}>
                  {i === 0 ? (
                    <Accordion.Item eventKey={i} key={i + "Accordion"}>
                      <Accordion.Header
                        className="accordinHeader"
                        key={i + "accordinHeader"}
                      >
                        {option.institutionName
                          ? option.institutionName.toUpperCase()
                          : ""}{" "}
                        , PROGRAM:
                        {option.program ? option.program.toUpperCase() : ""},
                        YEAR: {option.completedYear} , REGNO:{" "}
                        {option.registrationNo}, GPA: {option.gpa}
                      </Accordion.Header>
                      <Accordion.Body
                        className="accordionBody"
                        key={i + "accordionBody"}
                      >
                        {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    <Accordion.Item eventKey={i} key={i + "Accordion"}>
                      <Accordion.Header
                        className="accordinHeader"
                        key={i + "accordinHeader"}
                      >
                        {option.institutionName
                          ? option.institutionName.toUpperCase()
                          : ""}{" "}
                        , PROGRAM:
                        {option.program ? option.program.toUpperCase() : ""},
                        YEAR: {option.completedYear} , REGNO:{" "}
                        {option.registrationNo}, GPA: {option.gpa}
                      </Accordion.Header>
                      <Accordion.Body
                        className="accordionBody"
                        key={i + "accordionBody"}
                      >
                        {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {" "}
              {this.props.college_data.map((option, i) => (
                <div key={i}>
                  {i === 0 ? (
                    <Accordion.Item eventKey="0" key={i + "Accordion"}>
                      <Accordion.Header
                        className="accordinHeader"
                        key={i + "accordinHeader"}
                      >
                        {option.institutionName
                          ? option.institutionName.toUpperCase()
                          : ""}{" "}
                        , PROGRAM:
                        {option.program ? option.program.toUpperCase() : ""},
                        YEAR: {option.completedYear} , REGNO:{" "}
                        {option.registrationNo}, GPA: {option.gpa}
                      </Accordion.Header>
                      <Accordion.Body
                        className="accordionBody"
                        key={i + "accordionBody"}
                      >
                        {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    <Accordion.Item eventKey={i} key={i + "Accordion"}>
                      <Accordion.Header
                        className="accordinHeader"
                        key={i + "accordinHeader"}
                      >
                        {option.institutionName
                          ? option.institutionName.toUpperCase()
                          : ""}{" "}
                        , PROGRAM:
                        {option.program ? option.program.toUpperCase() : ""},
                        YEAR: {option.completedYear} , REGNO:{" "}
                        {option.registrationNo}, GPA: {option.gpa}
                      </Accordion.Header>
                      <Accordion.Body
                        className="accordionBody"
                        key={i + "accordionBody"}
                      >
                        {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </div>
              ))}
            </>
          )}
        </Accordion>
      </div>
    );
  }
}

export default DisplayCollege;
