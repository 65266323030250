import React, { Component } from "react";
import { FaRegHandPointRight } from "react-icons/fa";
export default class KinInstruction extends Component {
  render() {
    return (
      <>
        <p>
          <b>Instructions</b>
        </p>
        <hr />

        <ul>
          Please select your next of kin and fill in the information.
          <li>
            2. Select next of kin and fill in the information.{" "}
            <FaRegHandPointRight size={25} />
          </li>
        </ul>
      </>
    );
  }
}
