import React, { Component } from "react";
import { Container } from "reactstrap";
import api from "../../util/api";
import params from "../../util/api_routes/params";
import PerformDeclaration from "./PerformDeclaration";
import DeclarationInstruction from "./DeclarationInstruction";
import Alert from "@mui/material/Alert";
import Submission from "../submission/index";

class Declaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [{ title: "Dashboard", link: "#" }],
      user_data: [],
      school_data: [],
      high_school_data: [],
      programs_data: [],
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }
  componentDidMount() {
    this.getApplicantProfile();
    this.getSecondarySchools();
    this.getHighSchools();
    this.getApplyingProgram();
  }
  getApplicantProfile = () => {
    this.setState({ user_data: JSON.parse(localStorage.getItem("authUser")) });
  };
  getSecondarySchools = async () => {
    this.setState({ info_loading: true });
    await api
      .get(params.OLEVEL_LIST + parseInt(localStorage.getItem("id")))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              school_data: res.data.data,
              info_loading: false,
              network_error: false,
            });
          }
        } else {
          this.setState({
            school_data: [],
            info_loading: false,
            network_error: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ info_loading: false, network_error: true });
      });
  };

  getHighSchools = async () => {
    this.setState({ info_loading: true });
    await api
      .get(params.ALEVEL_LIST + parseInt(localStorage.getItem("id")))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              high_school_data: res.data.data,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
          }
        } else {
          this.setState({
            school_data: [],
            info_loading: false,
            network_error: false,
            filling_arrow: "right",
          });
        }
      })
      .catch((err) => {
        this.setState({
          info_loading: false,
          network_error: true,
        });
      });
  };

  getApplyingProgram = () => {
    this.setState({ info_loading: true });
    api
      .get(params.GET_APPLIED_PROGRAM + parseInt(localStorage.getItem("id")))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              programs_data: res.data.data,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
          }
        } else {
          this.setState({
            programs_data: [],
            info_loading: false,
            network_error: false,
            filling_arrow: "right",
          });
        }
      })
      .catch((err) => {
        this.setState({
          info_loading: false,
          network_error: true,
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">Declaration</div>
                <div className="float-right card-header-title">
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-body">
                <div className="block">
                  {this.state.stage < 8 ? (
                    <>
                      {" "}
                      <div className="row">
                        <div className="col-sm-12">
                          <Alert severity="warning">
                            To declare and submit your application. Please fill
                            all the information in the previous stages
                          </Alert>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {this.state.stage === 8 ? (
                        <>
                          <div className="row">
                            <div className="col-sm-1">{/*Display steps*/}</div>
                            <div className="col-sm-5">
                              <DeclarationInstruction
                              // status={this.state.filling_arrow}
                              />
                            </div>
                            <div className="col-sm-6">
                              <PerformDeclaration
                                user_data={this.state.user_data}
                                secondary_school={this.state.school_data}
                                high_school={this.state.high_school_data}
                                programs_data={this.state.programs_data}
                              />
                            </div>
                          </div>

                          {/* //-----------dermacation-------// */}
                        </>
                      ) : (
                        <>
                          {this.state.stage > 8 ? (
                            <>
                              {" "}
                              <Submission />
                            </>
                          ) : (
                            <></>
                          )}{" "}
                          {/* <div className="row">
                            <div className="col-sm-12">
                              <Alert severity="success">
                                <strong>Success!</strong> You have successfully
                                submitted your application. Wait for final
                                selection
                              </Alert>
                            </div>
                          </div> */}
                          {/* //-----------dermacation-------// */}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default Declaration;
