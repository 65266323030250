import React, { Component } from "react";
import { Container } from "reactstrap";
import ReactLoading from "react-loading";
import CollegeDisplay from "./collegeDisplay";
import CollegeFillingInstruction from "./collegeFillingInstruction";
import AddCollege from "./addCollege";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import Alert from "@mui/material/Alert";
class College extends Component {
  state = {
    answer_id: null,
    filling_arrow: "right",
    college_data: [],
    stage: parseInt(localStorage.getItem("stageid")),
    info_loading: null,
    network_error: false,
  };
  componentDidMount() {
    this.getCollegeList();
  }

  // getCollegeList = () => {
  //   let data = [];
  //   this.setState({
  //     college_data: data,
  //     filling_arrow: "right",
  //     info_loading: false,
  //   });
  // };

  getCollegeList = async () => {
    this.setState({ info_loading: true });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      awardId: parseInt(localStorage.getItem("awardId")),
    };
    await api
      .post(params.GET_COLLEGE_LIST, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              college_data: res.data.listdata,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
          }
        } else {
          this.setState({
            college_data: [],
            info_loading: false,
            network_error: false,
            filling_arrow: "right",
          });
        }
      })
      .catch((err) => {
        this.setState({
          info_loading: false,
          network_error: true,
        });
      });
  };

  render() {
    console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  Certificate, Diploma, Bachelor and Postgraduate Education
                </div>
                <div className="float-right card-header-title">
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-body">
                <div className="block">
                  {this.state.info_loading === true ? (
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-2">
                        <ReactLoading type="cylon" color="#000" />
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  ) : (
                    <>
                      {this.state.network_error === true ? (
                        <>
                          <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                              <div className="alert alert-warning">
                                <strong>Warning!</strong> You are facing a
                                connection problem, please contact the system
                                administrator OR try later
                              </div>
                            </div>
                            <div className="col-sm-1"></div>
                          </div>
                        </>
                      ) : (
                        <>
                          {this.state.college_data.length > 0 ? (
                            <>
                              {this.state.stage === 6 ? (
                                <>
                                  <div className="row">
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-4">
                                      <CollegeFillingInstruction
                                        status={this.state.filling_arrow}
                                      />
                                      <AddCollege
                                        college_data={this.state.college_data}
                                      />
                                    </div>
                                    <div className="col-sm-7">
                                      <CollegeDisplay
                                        college_data={this.state.college_data}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {this.state.stage > 6 ? (
                                    <>
                                      <div className="row">
                                        <div className="col-sm-1"></div>
                                        <div className="col-sm-10">
                                          <CollegeDisplay
                                            college_data={
                                              this.state.college_data
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-1"></div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <>
                                        <div className="row">
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-10">
                                            <Alert severity="warning">
                                              Please fill the previous stages
                                              Information
                                            </Alert>
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>
                                      </>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {this.state.stage < 6 ? (
                                <>
                                  {" "}
                                  <Alert severity="warning">
                                    <strong>Warning!</strong> Fill previous
                                    details before filling college Information
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  {this.state.stage === 6 ? (
                                    <div className="row">
                                      <div className="col-sm-1">
                                        {/*Display steps*/}
                                      </div>
                                      <div className="col-sm-4">
                                        <CollegeFillingInstruction
                                          status={this.state.filling_arrow}
                                        />
                                      </div>
                                      <div
                                        className="col-sm-7"
                                        style={{ paddingTop: "55px" }}
                                      >
                                        <AddCollege
                                          college_data={this.state.college_data}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      <Alert severity="info">
                                        <strong>Information!</strong> You have
                                        not studied any tertially (College)
                                        education
                                      </Alert>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default College;
