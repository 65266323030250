import api from "./api";
import params from "./api_routes/params";
export default async function changeStage(payLoad) {
  let status = 0;
  await api
    .post(params.CHANGE_STAGE, payLoad)
    .then((res) => {
      if (res.status === 200) {
        let stage =
          res.data.data === undefined || res.data.data === null
            ? localStorage.getItem("stageid")
            : res.data.data;
        localStorage.setItem("stageid", stage);
        status = 1;
      } else {
        status = 2;
      }
    })
    .catch((error) => {
      status = 0;
    });
  return status;
}
