import React, { Component } from "react";
import { Container } from "react-bootstrap";
import ReactLoading from "react-loading";
import HighSchoolInstruction from "../high-school/highSchoolFillingInstruction";
import HighSchoolDisplay from "../high-school/highSchoolDisplay";
import AddHighSchool from "../high-school/addHighSchool";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import Alert from "@mui/material/Alert";
class HighSchool extends Component {
  state = {
    answer_id: null,
    school_data: [],
    info_loading: null,
    network_error: false,
    filling_arrow: null,
    stage: parseInt(localStorage.getItem("stageid")),
  };

  componentDidMount() {
    this.getSecondarySchools();
  }

  handleSelectionInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getSecondarySchools = async () => {
    this.setState({ info_loading: true });
    await api
      .get(params.ALEVEL_LIST + parseInt(localStorage.getItem("id")))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              school_data: res.data.data,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
          }
        } else {
          this.setState({
            school_data: [],
            info_loading: false,
            network_error: false,
            filling_arrow: "right",
          });
        }
      })
      .catch((err) => {
        this.setState({
          info_loading: false,
          network_error: true,
        });
      });
  };

  render() {
    console.log(this.state.stage);
    console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  Advanced Secondary Education (High School)
                </div>
                <div className="float-right card-header-title">
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>

              <div className="card-body">
                <div className="block">
                  {this.state.info_loading === true ? (
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-2">
                        <ReactLoading type="cylon" color="#000" />
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  ) : (
                    <>
                      {this.state.network_error === true ? (
                        <>
                          <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                              <div className="alert alert-warning">
                                <strong>Warning!</strong> You are facing a
                                connection problem, please contact the system
                                administrator OR try later
                              </div>
                            </div>
                            <div className="col-sm-1"></div>
                          </div>
                        </>
                      ) : (
                        <>
                          {this.state.school_data.length > 0 ? (
                            <>
                              {this.state.stage === 5 ? (
                                <>
                                  <div className="row">
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-5">
                                      <HighSchoolInstruction
                                        status={this.state.filling_arrow}
                                      />
                                      <AddHighSchool
                                        school_data={this.state.school_data}
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <HighSchoolDisplay
                                        high_school={this.state.school_data}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {this.state.stage > 5 ? (
                                    <>
                                      {" "}
                                      <div className="row">
                                        {" "}
                                        <div className="col-sm-1"></div>
                                        <div className="col-sm-10">
                                          {" "}
                                          <HighSchoolDisplay
                                            high_school={this.state.school_data}
                                          />
                                        </div>
                                        <div className="col-sm-1"></div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <>
                                        <div className="row">
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-10">
                                            <Alert severity="warning">
                                              Please fill the previous stages
                                              Information
                                            </Alert>
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>
                                      </>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {this.state.stage < 5 ? (
                                <>
                                  {" "}
                                  <Alert severity="warning">
                                    <strong>Warning!</strong> Fill previous
                                    details before filling high school
                                    Information
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  {this.state.stage === 5 ? (
                                    <div className="row">
                                      <div className="col-sm-1">
                                        {/*Display steps*/}
                                      </div>
                                      <div className="col-sm-4">
                                        <HighSchoolInstruction
                                          status={this.state.filling_arrow}
                                        />
                                      </div>
                                      <div
                                        className="col-sm-7"
                                        style={{ paddingTop: "55px" }}
                                      >
                                        <AddHighSchool
                                          school_data={this.state.school_data}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <Alert severity="info">
                                      <strong>Information!</strong> You have not
                                      studied high school
                                    </Alert>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default HighSchool;
