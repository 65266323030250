import axios from "axios";

const TOKEN = JSON.parse(localStorage.getItem("token"));
const BASE_URL = process.env.APP_SERVER_URL;
// const BASE_URL = process.env.REACT_APP_SERVER_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common["Authorization"] =
  TOKEN !== null ? `Bearer ${TOKEN}` : null;

axios.defaults.headers.common["Accept"] = `application/json`;
axios.defaults.headers.common["Content-Type"] = `application/json`;

export default axios;
