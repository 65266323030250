import React, { Component } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import ReactLoading from "react-loading";
import api from "../../util/api";
import params from "../../util/api_routes/params";
import Unconfirm from "../Unconfirm/index";
import Alert from "@mui/material/Alert";
import ConfirmInstruction from "./ConfirmInstruction";
import PerformConfirmation from "./PerformConfirmation";
import UnconfirmInstruction from "../Unconfirm/UnconfirmInstruction";
import PerformUnconfirmation from "../Unconfirm/PerformUnconfirmation";
import CancelInstruction from "../Cancel/CancelInstruction";
import PerformCancellation from "../Cancel/PerformCancellation";
import UnrejectInformation from "../Unreject/UnrejectInformation";
import PerformUnrejection from "../Unreject/PerformUnrejection";
import { FaRegEdit, FaUpload, FaRegSave } from "react-icons/fa";

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info_loading: null,
      network_error: false,
      stage: parseInt(localStorage.getItem("stageid")),
      filling_arrow: "",
      stage_next: "",
      confirm_status: "",
    };
  }

  componentDidMount() {
    this.performConfirmation();
  }

  performConfirmation = async () => {
    this.setState({
      info_loading: true,
    });
    // await api
    //   .get(params.CONFIRMATION_STATUS + localStorage.getItem("id"))
    //   .then((res) => {
    //     if (res.status === 200) {
    //       if (res.data.stsCode === "0000") {
    //         this.setState({
    //           confirm_status: res.data.data,
    //           info_loading: false,
    //           network_error: false,
    //           filling_arrow: "right",
    //         });
    //         // console.log(this.state.next_0f_kin_data);
    //       }
    //     } else {
    //       this.setState({
    //         confirm_status: 0,
    //         info_loading: false,
    //         network_error: true,
    //         filling_arrow: "right",
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     this.setState({ network_error: true, info_loading: false });
    //   });
    //nipe vitu vyangu vinne vile, status,stage,programme,campus na reasons kama mtu amefeli

    this.setState({
      confirm_status: 4,
      info_loading: false,
      network_error: false,
      filling_arrow: "right",
    });
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  {/* Confirmation for multiple selection */}
                </div>
                <div className="float-right card-header-title">
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-body">
                <div className="block">
                  {this.state.info_loading === true ? (
                    <>
                      {" "}
                      <ReactLoading type="cylon" color="#000" />
                    </>
                  ) : (
                    <>
                      {this.state.network_error === true ? (
                        <div className="row">
                          <div className="col-sm-1"></div>
                          <div className="col-sm-10">
                            <div className="alert alert-warning">
                              <strong>Warning!</strong> You are facing a
                              connection problem, please contact the system
                              administrator OR try later
                            </div>
                          </div>
                          <div className="col-sm-1"></div>
                        </div>
                      ) : (
                        <>
                          {this.state.confirm_status === 2 ? (
                            <>
                              <div className="row">
                                <div className="col-sm-1">
                                  {/*Display steps*/}
                                </div>
                                <div className="col-sm-5">
                                  {" "}
                                  <ConfirmInstruction />
                                </div>

                                <div className="col-sm-6">
                                  <PerformConfirmation />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {" "}
                              {this.state.confirm_status === 3 ? (
                                <>
                                  <div className="row">
                                    <div className="col-sm-1">
                                      {/*Display steps*/}
                                    </div>
                                    <div className="col-sm-5">
                                      {" "}
                                      <UnconfirmInstruction />
                                    </div>

                                    <div className="col-sm-6">
                                      <PerformUnconfirmation />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {this.state.confirm_status === 1 ? (
                                    <>
                                      <div className="row">
                                        <div className="col-sm-1">
                                          {/*Display steps*/}
                                        </div>
                                        <div className="col-sm-5">
                                          {" "}
                                          <CancelInstruction />
                                        </div>

                                        <div className="col-sm-6">
                                          <PerformCancellation />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {this.state.confirm_status === 4 ? (
                                        <>
                                          <div className="row">
                                            <div className="col-sm-1">
                                              {/*Display steps*/}
                                            </div>
                                            <div className="col-sm-5">
                                              {" "}
                                              <UnrejectInformation />
                                            </div>

                                            <div className="col-sm-6">
                                              <PerformUnrejection />
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {this.state.confirm_status === 0 ? (
                                            <>
                                              <Alert severity="warning">
                                                Sorry! You have not been
                                                selected to join DMI. Please try
                                                again next time.. Thanks for
                                                your interest to join DMI.
                                              </Alert>
                                            </>
                                          ) : null}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {/* <Alert severity="success">
                                Congratulation!, You have been admitted to join
                                programme --- in --- Campus CLICK HERE to start
                                registration process
                              </Alert>
                              <div>
                                <Unconfirm />
                              </div> */}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default Confirm;
