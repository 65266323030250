import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import Alert from "@mui/material/Alert";
import { options } from "toastr";

class DisplayResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }
  render() {
    var info = Array.from(this.props.taarifa);
    return (
      <>
        <div className="col-sm-12">
          <h5>
            <Alert severity="warning">
              Sorry!!! You have not met entry requirement.
            </Alert>
          </h5>

          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: "#f1f5f7",
                    fontWeight: "bold",
                    fontFamily: "Helvetica",
                    fontSize: "13px",
                  }}
                  colSpan="4"
                >
                  REASONS:
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    backgroundColor: "#f1f5f7",
                    fontWeight: "bold",
                    fontFamily: "Helvetica",
                    fontSize: "12px",
                  }}
                >
                  CAMPUS
                </th>
                <th
                  style={{
                    backgroundColor: "#f1f5f7",
                    fontWeight: "bold",
                    fontFamily: "Helvetica",
                    fontSize: "12px",
                  }}
                >
                  CHOICE
                </th>
                <th
                  style={{
                    backgroundColor: "#f1f5f7",
                    fontWeight: "bold",
                    fontFamily: "Helvetica",
                    fontSize: "12px",
                  }}
                >
                  PROGRAMME
                </th>
                <th
                  style={{
                    backgroundColor: "#f1f5f7",
                    fontWeight: "bold",
                    fontFamily: "Helvetica",
                    fontSize: "12px",
                  }}
                >
                  REASON
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {console.log(this.props.final_selection)} */}
              {info.map((option, i) => (
                <tr key={i}>
                  <td
                    style={{
                      fontStyle: "italic",
                    }}
                  >
                    {" "}
                    {option.campus}
                  </td>
                  <td> {option.choice}</td>
                  <td> {option.program}</td>
                  <td> {option.reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default DisplayResults;
