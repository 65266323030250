import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import ReactLoading from "react-loading";

class Cancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info_loading: null,
      network_error: false,
      stage: parseInt(localStorage.getItem("stageid")),
      filling_arrow: "",
      stage_next: "",
    };
  }
  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  Unconfirmation for multiple selection
                </div>
                <div className="float-right card-header-title">
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-body">
                <div className="block">
                  {this.state.info_loading === true ? (
                    <>
                      {" "}
                      <ReactLoading type="cylon" color="#000" />
                    </>
                  ) : (
                    <>
                      {this.state.network_error === true ? (
                        <div className="row">
                          <div className="col-sm-1"></div>
                          <div className="col-sm-10">
                            <div className="alert alert-warning">
                              <strong>Warning!</strong> You are facing a
                              connection problem, please contact the system
                              administrator OR try later
                            </div>
                          </div>
                          <div className="col-sm-1"></div>
                        </div>
                      ) : (
                        <>Cancel</>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default Cancel;
