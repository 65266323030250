import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import monthsList from "../../../static/months";
import dayList from "../../../static/days";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ReactLoading from "react-loading";
import toTitleconverttoTitle from "../../../util/toTitleConveter";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import hashPasscode from "../../../util/hashPasscode";
import tzPhoneNumberValidator from "../../../util/tzPhoneNumberValidator";
import emailValidator from "../../../util/emailValidator";

class ShortcourseContinuing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      declare: "",
      yesIsOpen: false,
      noIsOpen: false,
      redirect: false,
      hide: true,
      dataCommunities: [],
      communityId: "",
      dmiNumber: "",
      fields: {},
      errors: {},
      countries: [],
      regions: [],
      tzregion: "",
      districts: [],
      tzdistrict: "",
      region: "",
      district: "",
      phone: "",
      username: "",
      password: "",
      citizenship: "",
      dob: "",
      date: "",
      dayofBirth: "",
      month: "",
      yearofBirthday: "",
      confirmpassword: "",
    };
  }

  componentDidMount() {
    const programmeId = this.props.programmeId;
    this.setState({
      programmeId: programmeId,
    });
    this.getCurrentIntake();
    this.getCountries();
    this.getRegions();
    // this.getShortCourses();
    this.getCommunities();
  }

  getCurrentIntake = async () => {
    await api
      .get(params.CURRENT_INTAKE)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              academic_year_id: res.data.data.academicYear,
              academic_year: res.data.data.academicYear,
              intake_id: res.data.data.intake.id,
              intake_name: res.data.data.intake.intake,
              levels: res.data.data.awards,
              applicationProgramme: 1,
            });
          }
          if (res.data.stsCode === "0001") {
            this.setState({ applicationProgramme: null });
          }
        } else {
          this.setState({ serverError: true });
        }
        // console.log(res.data.stsCode);
      })
      .catch((error) => {
        if (error) {
          this.setState({ serverError: true });
        }
      });
  };
  getCountries = async () => {
    try {
      await api
        .get(params.COUNTRY_LIST)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                countries: res.data.data,
              });
            }
            if (res.data.stsCode === "0001") {
              this.setState({ applicationProgramme: null });
            }
          } else {
            this.setState({ serverError: true });
          }
        })
        .catch((err) => {
          this.setState({ serverError: true });
        });
    } catch (error) {
      this.setState({ serverError: true });
    }
  };

  getRegions = async () => {
    try {
      api
        .get(params.REGION_LIST)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                regions: res.data.data,
              });
            }
            if (res.data.stsCode === "0001") {
              this.setState({ applicationProgramme: null });
            }
          } else {
            this.setState({ serverError: true });
          }
        })
        .catch((err) => {
          this.setState({ serverError: true });
        });
    } catch (error) {
      this.setState({ serverError: true });
    }
  };

  getDistrict = async (id) => {
    this.setState({ district_loadig: true });
    try {
      await api
        .get(params.DISTRICT_LIST + "/" + id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                districts: res.data.data,
                district_loadig: false,
              });
            }
          } else {
            this.setState({});
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });

    if (e.target.name === "disabilityDecision") {
      e.target.value === "1"
        ? this.setState({ disabilityState: true })
        : this.setState({ disabilityState: false });
    }

    if (e.target.name === "residence") {
      if (e.target.value === "") {
        this.setState({ showRegion: "" });
      }
      if (e.target.value === "Tanzania") {
        this.setState({ showRegion: true });
      }
      if (e.target.value !== "Tanzania" && e.target.value !== "") {
        this.setState({ showRegion: false });
      }
    }
    if (e.target.name === "tzregion") {
      this.setState({ showDistrict: true });
      if (e.target.value === "") {
        this.setState({ district_loadig: false });
      }
      if (e.target.value !== "") {
        this.setState({ districts: [] });
        const id = e.target.value.split(",");
        this.getDistrict(parseInt(id[0]));
        this.setState({ region: id[1] });
      }
    }
  };

  getCommunities = async () => {
    await api
      .get(params.GET_COMMUNITIES)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            dataCommunities: res.data.data,
          });
        } else {
          this.setState({ serverError: true });
        }
        // console.log(res.data.stsCode);
      })
      .catch((error) => {
        if (error) {
          this.setState({ serverError: true });
        }
      });
  };

  hideSwitch = (ev) => {
    this.setState({ hide: !this.state.hide });
  };

  submissionValidationShortCourse = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //password strength
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var specialChar = /[^A-Za-z0-9]/g;

    // added fields
    if (!fields["firstName"] || typeof fields["firstName"] === undefined) {
      formIsValid = false;
      errors["firstName"] = "* Please fill first name.";
    }

    if (!fields["communityId"] || typeof fields["communityId"] === undefined) {
      formIsValid = false;
      errors["communityId"] = "* Please select community.";
    }

    if (!fields["dmiNumber"] || typeof fields["dmiNumber"] === undefined) {
      formIsValid = false;
      errors["dmiNumber"] = "* Please enter DMI Number.";
    }

    if (
      !fields["disabilityDecision"] ||
      typeof fields["disabilityDecision"] === undefined
    ) {
      formIsValid = false;
      errors["disabilityDecision"] = "* Please select disability status.";
    }

    // if (!fields["disability"] || typeof fields["disability"] === undefined) {
    //   formIsValid = false;
    //   errors["disability"] = "* Please select disability.";
    // }

    if (!fields["sex"] || typeof fields["sex"] === undefined) {
      formIsValid = false;
      errors["sex"] = "* Please select your sex.";
    }

    //end added fields

    if (
      !fields["dayofBirth"] ||
      typeof fields["dayofBirth"] === undefined ||
      !fields["month"] ||
      typeof fields["month"] === undefined ||
      !fields["yearofBirthday"] ||
      typeof fields["yearofBirthday"] === undefined
    ) {
      formIsValid = false;
      errors["birthday"] = "* day, month or year of birth is empty.";
    }
    if (!fields["phone"] || typeof fields["phone"] === undefined) {
      formIsValid = false;
      errors["phone"] = "* Please fill the phone number.";
    }
    if (tzPhoneNumberValidator(fields["phone"]) === false) {
      formIsValid = false;
      errors["phone"] = "* Phone number is wrong,please check and re-type.";
    }
    if (!fields["citizenship"] || typeof fields["citizenship"] === undefined) {
      formIsValid = false;
      errors["citizenship"] = "* Please select your.";
    }
    if (!fields["residence"] || typeof fields["residence"] === undefined) {
      formIsValid = false;
      errors["residence"] = "* Please select country of residency.";
    }
    if (fields["residence"] === "Tanzania") {
      if (!fields["tzregion"] || typeof fields["tzregion"] === undefined) {
        formIsValid = false;
        errors["tzregion"] = "* Please select region.";
      }
      if (!fields["tzdistrict"] || typeof fields["tzdistrict"] === undefined) {
        formIsValid = false;
        errors["tzdistrict"] = "* Please select district.";
      }
    }
    if (fields["residence"] !== "Tanzania") {
      if (!fields["region"] || typeof fields["region"] === undefined) {
        formIsValid = false;
        errors["region"] = "* Please fill the  region.";
      }
      if (!fields["district"] || typeof fields["district"] === undefined) {
        formIsValid = false;
        errors["district"] = "* Please fill the  district.";
      }
    }
    if (!fields["username"] || typeof fields["username"] === undefined) {
      formIsValid = false;
      errors["username"] = "* Please fill the username.";
    }
    if (emailValidator(fields["username"]) === false) {
      formIsValid = false;
      errors["username"] = "* Email is invalid.";
    }
    if (!fields["password"] || typeof fields["password"] === undefined) {
      formIsValid = false;
      errors["password"] = "* Please fill the password.";
    } else {
      //password validator
      if (!lowerCase.test(fields["password"])) {
        formIsValid = false;
        errors["password"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (!upperCase.test(fields["password"])) {
        formIsValid = false;
        errors["password"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (!numbers.test(fields["password"])) {
        formIsValid = false;
        errors["password"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (!specialChar.test(fields["password"])) {
        formIsValid = false;
        errors["password"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (fields["password"].length < 8) {
        formIsValid = false;
        errors["password"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      }
    }
    // if (passwordValidator(fields["password"]) === false) {
    //   formIsValid = false;
    //   errors["password"] = "* Password is invalid or is less than 8 characters";
    // }
    if (!fields["pass"] || typeof fields["pass"] === undefined) {
      formIsValid = false;
      errors["pass"] = "* Confirm the password.";
    }
    if (fields["password"] !== fields["pass"]) {
      formIsValid = false;
      errors["pass"] = "* Passwords does not match.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  createAccountShortCourse = async (e) => {
    console.log("nafika");
    e.preventDefault();
    if (this.submissionValidationShortCourse()) {
      this.setState({ loading: true });
      console.log("nafika2");
      let payLoad = {
        email: this.state.username,
        password: await hashPasscode(this.state.password),
        mobile: this.state.phone,
        sex: this.state.sex,
        dob:
          this.state.yearofBirthday.toString() +
          "-" +
          this.state.month.toString() +
          "-" +
          this.state.dayofBirth.toString(),
        nationality: this.state.citizenship,
        country: this.state.residence,
        // region: this.state.region,
        region:
          this.state.region !== "" ? this.state.region : this.state.tzregion,
        district:
          this.state.district !== ""
            ? this.state.district
            : this.state.tzdistrict,
        programmeId: this.state.programmeId,
        firstname: this.state.firstName,
        middlename: this.state.middleName,
        surname: this.state.surname,
        nationalId: this.state.nida ? this.state.nida : null,
        disability:
          this.state.disability !== null ? this.state.disability : null,
        levelId: 6,
        // communityId: this.state.communityId,
        dmiNumber: this.state.dmiNumber !== "" ? this.state.dmiNumber : null,
      };

      await api
        .post(params.USER_ACCOUNT_SHORT_COURSE, payLoad)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                account_created: true,
                loading: false,
                account_existence_status: "",
              });
              alert(
                "Congratulation! You have successfully created an account, Login to your Account"
              );
              this.props.history.push("/login");
              //  setTimeout(this.props.history.push("/"), 6000);
              // short_course: false,
            } else {
              this.setState({
                account_created: false,
                short_course: true,
                account_existence_status: true,
              });
              console.log(res.data.stsCode);
              alert(res.data.stsMessage);
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          if (error) {
            // console.log(error);
          }
        });
    }
  };

  render() {
    // console.log(parseInt(localStorage.getItem("stageid")));
    // let count = 1;
    let maxOffset = 40;
    let thisYear = new Date().getFullYear();
    let allYears = [];
    let yearsofBirth = [];

    for (let x = 0; x <= maxOffset; x++) {
      allYears.push(thisYear - x);
    }

    //console.log(thisYear - 10);
    let start = thisYear - 14; // initiate year
    for (let x = start; x > 1970; x--) {
      yearsofBirth.push(x);
    }

    const yearList = allYears.map((x) => {
      return (
        <option key={x} value={x}>
          {x}
        </option>
      );
    });

    const { hide } = this.state;
    return (
      <>
        <form onSubmit={this.createAccountShortCourse}>
          {/* onSubmit={this.openModelForm} */}
          <div className="form-group">
            <label className="label-class">
              Select Community
              <font className="label-comment">*</font>
            </label>

            <select
              type="number"
              name="communityId"
              value={this.state.name}
              onChange={this.handleInputState}
              className="selection-box  input-placeholder"
            >
              <option key=""> Select Community</option>
              {/* {console.log(dataShortCourses)} */}
              {this.state.dataCommunities.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {this.state.errors.communityId ? (
              <div className="errorMsg">{this.state.errors.communityId}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label className="label-class">
              Enter DMI Number
              <font className="label-comment"></font>
            </label>
            <input
              name="dmiNumber"
              type="text"
              value={this.state.name}
              onChange={this.handleInputState}
              className="form-control"
              placeholder="DMI NUMBER"
            />
            {this.state.errors.dmiNumber ? (
              <div className="errorMsg">{this.state.errors.dmiNumber}</div>
            ) : null}
          </div>

          <fieldset className="scheduler-border">
            <legend className="scheduler-border">
              Date of Birth, Address and Other Information
            </legend>

            {/* full names */}
            <div className="form-group">
              <label className="label-class">
                Full Names <font className="label-comment">*</font>
              </label>
              <br></br>
              <input
                name="firstName"
                type="text"
                value={this.state.name}
                onChange={this.handleInputState}
                className="custom-select custom-select-md selectpicker col-sm-3"
                placeholder="First name"
              />
              {this.state.errors.firstName ? (
                <div className="errorMsg">{this.state.errors.firstName}</div>
              ) : null}
              &nbsp;
              <input
                name="middleName"
                type="text"
                value={this.state.name}
                onChange={this.handleInputState}
                className="custom-select custom-select-md selectpicker col-sm-3"
                placeholder="Middle name"
              />
              {this.state.errors.middleName ? (
                <div className="errorMsg">{this.state.errors.middleName}</div>
              ) : null}
              &nbsp;
              <input
                name="surname"
                type="text"
                value={this.state.name}
                onChange={this.handleInputState}
                className="custom-select custom-select-md selectpicker col-sm-3"
                placeholder="Last name(surname)"
              />
              {this.state.errors.surname ? (
                <div className="errorMsg">{this.state.errors.surname}</div>
              ) : null}
            </div>
            {/* full names */}

            {/* sex */}
            <div className="form-group">
              <label className="label-class">Sex</label>
              <select
                className="selection-box  input-placeholder"
                onChange={this.handleInputState}
                name="sex"
                value={this.state.name}
              >
                <option key="1"> Please select your sex</option>
                <option key="2" value="Male">
                  {" "}
                  Male
                </option>
                <option key="3" value="Female">
                  {" "}
                  Female
                </option>
              </select>
              {this.state.errors.sex ? (
                <div className="errorMsg">{this.state.errors.sex}</div>
              ) : null}
            </div>
            {/* sex */}

            <div className="form-group">
              <label className="label-class">
                Date of Birth <font className="label-comment">*</font>
              </label>
              <br></br>
              <select
                type="text"
                name="dayofBirth"
                value={this.state.dayofBirth}
                onChange={this.handleInputState}
                className="custom-select custom-select-md selectpicker col-sm-3"
                aria-readonly="true"
              >
                <option key=""> Select Day </option>
                {dayList.days.map((option) => (
                  <option key={option.day} value={option.day}>
                    {option.day}
                  </option>
                ))}
              </select>
              &nbsp;
              <select
                type="text"
                name="month"
                value={this.state.month}
                onChange={this.handleInputState}
                className="custom-select custom-select-md selectpicker col-sm-3"
                aria-readonly="true"
              >
                <option key=""> Select Month </option>
                {monthsList.months.map((option) => (
                  <option key={option.number} value={option.number}>
                    {option.month}
                  </option>
                ))}
              </select>
              &nbsp;
              <select
                type="text"
                name="yearofBirthday"
                value={this.state.yearofBirthday}
                onChange={this.handleInputState}
                className="custom-select custom-select-md selectpicker col-sm-3"
                aria-readonly="true"
              >
                <option key=""> Select Year </option>
                {yearsofBirth.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {this.state.errors.birthday ? (
                <div className="errorMsg">{this.state.errors.birthday}</div>
              ) : null}
            </div>

            {/* additional fields short course */}
            <div className="form-group">
              <label className="label-class">
                National ID
                <font className="label-comment"></font>
              </label>
              <input
                name="nationalId"
                type="text"
                value={this.state.name}
                onChange={this.handleInputState}
                className="form-control"
                placeholder="Nida Number (Optional)"
              />
              {this.state.errors.nationalId ? (
                <div className="errorMsg">{this.state.errors.nationalId}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label className="label-class">
                Disability
                <font className="label-comment">*</font>
              </label>
              <select
                className="selection-box  input-placeholder"
                onChange={this.handleInputState}
                name="disabilityDecision"
                value={this.state.name}
              >
                <option key="1"> Do you have disability?</option>
                <option key="2" value="1">
                  {" "}
                  Yes
                </option>
                <option key="3" value="2">
                  {" "}
                  No
                </option>
              </select>
              {this.state.errors.disabilityDecision ? (
                <div className="errorMsg">
                  {this.state.errors.disabilityDecision}
                </div>
              ) : null}
              {this.state.disabilityState === true ? (
                <>
                  <br />
                  <label className="label-class">
                    Select disability type
                    <font className="label-comment"></font>
                  </label>

                  <select
                    className="selection-box  input-placeholder"
                    onChange={this.handleInputState}
                    name="disability"
                    value={this.state.name}
                  >
                    <option key="1"> --select--</option>
                    <option key="2" value="vision Impairment">
                      {" "}
                      vision Impairment
                    </option>
                    <option key="3" value="deaf or hard of hearing">
                      {" "}
                      deaf or hard of hearing
                    </option>
                    <option key="4" value="mental health conditions">
                      {" "}
                      mental health conditions
                    </option>
                    <option key="5" value="intellectual disability">
                      {" "}
                      intellectual disability
                    </option>
                    <option key="6" value="acquired brain injury">
                      {" "}
                      acquired brain injury
                    </option>
                    <option key="7" value="autism spectrum disorder">
                      {" "}
                      autism spectrum disorder
                    </option>
                    <option key="8" value="Upper limb(s) disability">
                      {" "}
                      Upper limb(s) disability
                    </option>
                    <option key="9" value="Lower limb(s) disability">
                      {" "}
                      Lower limb(s) disability
                    </option>
                    <option key="10" value="Mute">
                      {" "}
                      Mute
                    </option>
                  </select>
                  {/* {this.state.errors.disability ? (
                    <div className="errorMsg">
                      {this.state.errors.disability}
                    </div>
                  ) : null} */}
                </>
              ) : (
                <></>
              )}
            </div>

            {/* additional fields short course */}

            <div className="form-group">
              <label className="label-class">
                Phone
                <font className="label-comment">*</font>
              </label>
              <input
                name="phone"
                type="phone"
                value={this.state.name}
                onChange={this.handleInputState}
                className="form-control"
                placeholder="E.g. 0623000333"
              />
              {this.state.errors.phone ? (
                <div className="errorMsg">{this.state.errors.phone}</div>
              ) : null}
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-sm-6">
                  <label className="label-class">
                    Citizeship
                    <font className="label-comment">*</font>
                  </label>
                  <select
                    type="text"
                    name="citizenship"
                    value={this.state.citizenship}
                    onChange={this.handleInputState}
                    className="custom-select custom-select-md"
                    aria-readonly="true"
                  >
                    <option key="" value="">
                      -- Select citizenship --{" "}
                    </option>
                    {this.state.countries.map((options) => (
                      <option key={options.id} value={options.citizenship}>
                        {options.citizenship}
                      </option>
                    ))}
                  </select>
                  {this.state.errors.citizenship ? (
                    <div className="errorMsg">
                      {this.state.errors.citizenship}
                    </div>
                  ) : null}
                </div>
                <div className="col-sm-6">
                  <label className="label-class">
                    Country of Residence
                    <font className="label-comment">*</font>
                  </label>
                  <select
                    type="text"
                    name="residence"
                    value={this.state.residence}
                    onChange={this.handleInputState}
                    className="custom-select custom-select-md"
                    aria-readonly="true"
                  >
                    <option key="" value="">
                      -- Select country of residence --{" "}
                    </option>
                    {this.state.countries.map((options) => (
                      <option key={options.id} value={options.country}>
                        {options.country}
                      </option>
                    ))}
                  </select>
                  {this.state.errors.residence ? (
                    <div className="errorMsg">
                      {this.state.errors.residence}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {this.state.showRegion === true ? (
              <>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-6">
                      <label className="label-class">
                        Region
                        <font className="label-comment">*</font>
                      </label>
                      <select
                        type="text"
                        name="tzregion"
                        value={this.state.tzregion}
                        onChange={this.handleInputState}
                        className="custom-select custom-select-md"
                        aria-readonly="true"
                      >
                        <option key="" value="">
                          -- Select Region --{" "}
                        </option>
                        {this.state.regions.map((options) => (
                          <option
                            key={options.id}
                            value={
                              options.id +
                              "," +
                              toTitleconverttoTitle(options.region)
                            }
                          >
                            {toTitleconverttoTitle(options.region)}
                          </option>
                        ))}
                      </select>
                      {this.state.errors.tzregion ? (
                        <div className="errorMsg">
                          {this.state.errors.tzregion}
                        </div>
                      ) : null}
                    </div>

                    {this.state.showDistrict === true ? (
                      <div className="col-sm-6">
                        {this.state.district_loadig === true ? (
                          <ReactLoading type="cylon" color="#000" />
                        ) : (
                          <>
                            <label className="label-class">
                              District
                              <font className="label-comment">*</font>
                            </label>
                            <select
                              type="text"
                              name="tzdistrict"
                              value={this.state.tzdistrict}
                              onChange={this.handleInputState}
                              className="custom-select custom-select-md"
                              aria-readonly="true"
                            >
                              <option key="" value="">
                                -- Select District --{" "}
                              </option>
                              {this.state.districts.map((options) => (
                                <option
                                  key={options.id}
                                  value={toTitleconverttoTitle(
                                    options.district
                                  )}
                                >
                                  {toTitleconverttoTitle(options.district)}
                                </option>
                              ))}
                            </select>
                            {this.state.errors.tzdistrict ? (
                              <div className="errorMsg">
                                {this.state.errors.tzdistrict}
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ) : (
              <>
                {this.state.showRegion === false ? (
                  <>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-6">
                          <label className="label-class">
                            Region
                            <font className="label-comment">*</font>
                          </label>
                          <input
                            type="text"
                            name="region"
                            value={this.state.region}
                            onChange={this.handleInputState}
                            className="form-control"
                            placeholder="Region"
                          />
                          {this.state.errors.region ? (
                            <div className="errorMsg">
                              {this.state.errors.region}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-sm-6">
                          <label className="label-class">
                            District
                            <font className="label-comment">*</font>
                          </label>
                          <input
                            type="text"
                            name="district"
                            value={this.state.district}
                            onChange={this.handleInputState}
                            className="form-control"
                            placeholder="District"
                          />
                          {this.state.errors.district ? (
                            <div className="errorMsg">
                              {this.state.errors.district}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </fieldset>

          {/*End of Address Information*/}

          <fieldset className="scheduler-border">
            <legend className="scheduler-border">Login Information</legend>

            <div className="form-group">
              <label> Username (Must be an email address)* </label>
              <input
                type="email"
                name="username"
                value={this.state.username}
                onChange={this.handleInputState}
                className="form-control"
                placeholder="Email"
              />
              {this.state.errors.username ? (
                <div className="errorMsg">{this.state.errors.username}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label> Password * </label>
              <input
                name="password"
                // type="password"
                type={hide ? "password" : "text"}
                value={this.state.password}
                onChange={this.handleInputState}
                className="form-control"
                placeholder="Password"
              />
              <span
                className="password__show"
                onClick={this.hideSwitch}
                style={{
                  position: "absolute",
                  right: 60,
                }}
              >
                {hide ? <FaEye /> : <FaEyeSlash />}
              </span>
              {this.state.errors.password ? (
                <div className="errorMsg">{this.state.errors.password}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label> Confirm Password * </label>
              <input
                placeholder="Confirm Password"
                className="form-control"
                name="pass"
                // type="password"
                type={hide ? "password" : "text"}
                value={this.state.pass}
                onChange={this.handleInputState}
              />
              <span
                className="password__show"
                onClick={this.hideSwitch}
                style={{
                  position: "absolute",
                  right: 60,
                }}
              >
                {hide ? <FaEye /> : <FaEyeSlash />}
              </span>
              {this.state.errors.pass ? (
                <div className="errorMsg">{this.state.errors.pass}</div>
              ) : null}
            </div>
          </fieldset>

          <div className="row">
            <div className="col-sm-5"></div>
            <div className="col-sm-2 ">
              <button className="btn btn-round  btn-sm btn-primary verification-button">
                Finish <i className="ti-arrow-circle-right"></i>
              </button>
            </div>
            <div className="col-sm-2"></div>
          </div>
        </form>
      </>
    );
  }
}

const ShortcourseContinuing2 = withRouter(ShortcourseContinuing);
export default ShortcourseContinuing2;
