/* eslint-disable react/jsx-no-undef */
import React, { Component } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import ReactLoading from "react-loading";
// import { withRouter } from "react-router";
import Alert from "@mui/material/Alert";
import { FaRegSave } from "react-icons/fa";
import api from "../../util/api";
import params from "../../util/api_routes/params";
// import changeStage from "../../../util/changeStage";
class AddApplyingProgram extends Component {
  state = {
    loading: "",
    fields: {},
    errors: {},
    campus: [],
    campus_id: "",
    progams: [],
    submit_program: [],
    submission: [],
    choice: "",
    program_error: "",
    choice_error: "",
    not_filled_all: false,
    program_id: "",
    program_loading: false,
    network_error: false,
    network_error_loading_programm: false,
  };

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });

    if (e.target.name === "campus_id" && fields["campus_id"] !== "") {
      this.applyingProgram({
        campusId: fields["campus_id"],
        awardId: localStorage.getItem("awardId"),
      });
    }
    if (e.target.name === "campus_id" && fields["campus_id"] === "") {
      this.setState({ progams: [] });
    }
  };

  applyingProgram = async (data) => {
    this.setState({ program_loading: true, progams: [] });
    await api
      .post(params.CAMPUS_PROGRAM_LIST, data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              progams: res.data.data,
              program_loading: false,
              info_loading: false,
              network_error: false,
            });
          }
        } else {
          this.setState({
            progams: [],
            info_loading: false,
            network_error: false,
            program_loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          info_loading: false,
          network_error: true,
          program_loading: false,
        });
      });
  };

  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["campus_id"]) {
      formIsValid = false;
      errors["campus_id"] = "* Please select campus.";
    }
    if (!fields["program_id"]) {
      formIsValid = false;
      errors["program_id"] = "* Please select program.";
    }
    if (!fields["choice"]) {
      formIsValid = false;
      errors["choice"] = "* Please select choice.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleAlternate(event) {
    event.preventDefault();

    if (this.validateForm()) {
      if (this.state.program_id.length > 0 && this.state.choice !== "") {
        this.setState({ not_filled_all: false });
        var progName = this.state.program_id.split(",");
        // console.log(progName[0]);
        // // console.log(progName);
        // console.log(this.state.campus_id);
        // console.log(this.state.program_id);
        // console.log(this.state.submit_program);
        // console.log(this.state.submission);
        if (
          this.state.submit_program.some(
            (t) =>
              t.campusId === this.state.campus_id && t.programId === progName[0]
          )
        ) {
          // this.setState({
          //   applProfileId: "",
          //   campus_id: "",
          //   program_id: "",
          //   choice: "",
          // });
          this.setState({ program_error: true });
        } else {
          if (
            this.state.submit_program.some(
              (t) => t.choice === this.state.choice
            )
          ) {
            this.setState({ choice_error: true });
          } else {
            this.setState({ choice_error: false });
            this.setState({ program_error: false });
            let obj = {
              applicationProfileId: localStorage.getItem("id"),
              campusId: this.state.campus_id,
              programId: progName[0],
              programName: progName[1],
              choice: this.state.choice,
            };
            let obj1 = {
              applicationProfileId: localStorage.getItem("id"),
              campusId: this.state.campus_id,
              programId: progName[0],
              choice: this.state.choice,
            };
            this.state.submit_program.push(obj);
            this.state.submission.push(obj1);
            this.setState({
              applicationProfileId: "",
              campusId: "",
              programId: "",
              programName: "",
              choice: "",
            });
          }
        }
      } else {
        this.setState({ not_filled_all: true });
      }
    }
  }

  deleteProgram = (i) => {
    const programes = this.state.submit_program;
    const submission_data = this.state.submission;
    const filteredprograme = programes.filter(
      (programes, index) => index !== i
    );

    const filteredsubmission = submission_data.filter(
      (submission_data, index) => index !== i
    );
    this.setState({
      submit_program: filteredprograme,
      submission: filteredsubmission,
    });
  };

  submitApplyingProgram = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, network_error: false });
    let payLoad = { appliedPrograms: this.state.submission };
    await api
      .post(params.ADD_PROGRAM, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              campus: [],
              campus_id: "",
              progams: [],
              submit_program: [],
              choice: "",
              program_error: "",
              choice_error: "",
              not_filled_all: false,
              program_id: "",
              loading: false,
              program_loading: false,
              network_error: false,
            });
            window.location.reload();
          } else if (res.data.stsCode === "0001") {
            this.setState({
              particular_exists: true,
            });
          } else {
            this.setState({
              network_error: true,
              answer: "",
              loading: false,
            });
          }
          ///
        } else {
          this.setState({
            network_error: true,
            answer: "",
            loading: false,
          });
        }
      })
      .catch((error) => {});
  };
  render() {
    let count = 1;
    return (
      <>
        {this.state.loading === true ? (
          <>
            <ReactLoading type="cylon" color="#000" />
          </>
        ) : null}
        {this.state.network_error === true ? (
          <>
            <Alert onClose={this.closeNetworkWarning} severity="error">
              You are facing netwok problems — Please try again later or contact
              the system administrator!
            </Alert>
          </>
        ) : null}
        {this.state.particular_exists === true ? (
          <>
            <Alert onClose={this.closeAlertWarning} severity="warning">
              <strong>Warning!</strong> Such data exists. Please check your
              entries
            </Alert>
            <br></br>
          </>
        ) : null}
        <form onSubmit={this.submitApplyingProgram} key={"formprogram"}>
          <Row>
            {this.state.submit_program.length > 0 ? (
              <>
                <table
                  className="table table-bordered"
                  style={{ width: "90%" }}
                  key={"table" + 1}
                >
                  <thead>
                    <tr
                      style={{ borderColor: "#000", color: "#000" }}
                      key={"tr" + 1}
                    >
                      <td
                        colSpan=""
                        style={{
                          borderColor: "#000",
                          color: "#000",
                          width: "5%",
                        }}
                        key={"td" + 1}
                      >
                        #
                      </td>
                      <td
                        colSpan=""
                        style={{
                          borderColor: "#000",
                          color: "#000",
                          width: "60%",
                        }}
                        key={"td" + 2}
                      >
                        PROGRAM
                      </td>
                      <th
                        colSpan=""
                        style={{
                          borderColor: "#000",
                          color: "#000",
                          width: "10%",
                        }}
                      >
                        CHOICE
                      </th>
                      <th
                        colSpan=""
                        style={{
                          borderColor: "#000",
                          color: "#000",
                          width: "10%",
                        }}
                      >
                        CAMPUS
                      </th>
                      <td
                        colSpan=""
                        style={{
                          borderColor: "#000",
                          color: "#000",
                          width: "5%",
                        }}
                        key={"td" + 3}
                      >
                        ACTION
                      </td>
                    </tr>
                  </thead>
                  <tbody key={"tbody" + 3}>
                    {this.state.submit_program.map((opt, i) => (
                      <tr key={"tr" + i}>
                        <td
                          key={i + 0}
                          style={{
                            borderColor: "#000",
                            color: "#000",
                          }}
                        >
                          {count + i}
                        </td>
                        <td
                          key={i + 1}
                          style={{
                            borderColor: "#000",
                            color: "#000",
                          }}
                        >
                          {opt.programName}
                        </td>
                        <td
                          key={i + 2}
                          style={{
                            borderColor: "#000",
                            color: "#000",
                          }}
                        >
                          {opt.choice === "1" ? (
                            <>
                              1<sup>st</sup>
                            </>
                          ) : null}
                          {opt.choice === "2" ? (
                            <>
                              2<sup>nd </sup>
                            </>
                          ) : null}
                          {opt.choice === "3" ? (
                            <>
                              3<sup>rd</sup>
                            </>
                          ) : null}
                          {opt.choice === "4" ? (
                            <>
                              4<sup>th</sup>
                            </>
                          ) : null}
                          {opt.choice === "5" ? (
                            <>
                              5<sup>th</sup>
                            </>
                          ) : null}
                        </td>
                        <td
                          key={i + 3}
                          style={{
                            borderColor: "#000",
                            color: "#000",
                          }}
                        >
                          {opt.campusId}
                        </td>
                        <td
                          key={i + 4}
                          style={{
                            borderColor: "#000",
                            color: "#000",
                          }}
                        >
                          <Button
                            type="submit"
                            className="btn btn-round  btn-xs"
                            variant="danger"
                            style={{
                              fontSize: "12px",
                            }}
                            onClick={() => this.deleteProgram(i)}
                            key={i + "button"}
                          >
                            {" "}
                            - Remove{" "}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : null}
          </Row>
          {this.state.submit_program.length < 5 ? (
            <>
              <Row className="align-items-center">
                <Col>
                  <Form.Label> Select Campus *</Form.Label>
                  <select
                    name="campus_id"
                    className="selection-box  input-placeholder"
                    value={this.state.campus_id}
                    onChange={this.handleInputState}
                  >
                    <option key={0} value="">
                      {" "}
                      -- Select the Campus --{" "}
                    </option>

                    {this.props.campus.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.campusName}
                      </option>
                    ))}
                  </select>
                  {this.state.errors.campus_id ? (
                    <div className="errorMsg">
                      {this.state.errors.campus_id}
                    </div>
                  ) : null}

                  {this.state.errors.program_id || this.state.errors.choice ? (
                    <div className="errorMsg">
                      <br></br>
                    </div>
                  ) : null}
                </Col>

                {this.state.program_loading === true ? (
                  <>
                    <ReactLoading type="cylon" color="#000" />
                  </>
                ) : null}
                {this.state.network_error === true ? (
                  <>
                    <div className="alert alert-warning">
                      <strong>Warning!</strong> You are facing a connection
                      problem, please contact the system administrator OR try
                      later
                    </div>
                  </>
                ) : (
                  <>
                    {this.state.progams.length > 0 ? (
                      <>
                        <Col>
                          <Form.Label
                            htmlFor="inlineFormInputGroup"
                            visuallyHidden
                          >
                            Select Program
                            <font className="label-comment">*</font>
                          </Form.Label>
                          <select
                            name="program_id"
                            className="selection-box  input-placeholder mb"
                            value={this.state.program_id}
                            onChange={this.handleInputState}
                          >
                            <option key={0} value="">
                              {" "}
                              -- Progam --{" "}
                            </option>

                            {this.state.progams.map((option) => (
                              <option
                                key={option.id}
                                value={option.id + "," + option.progName}
                              >
                                {option.progName}
                              </option>
                            ))}
                          </select>
                          {this.state.errors.program_id ? (
                            <div className="errorMsg">
                              {this.state.errors.program_id}
                            </div>
                          ) : null}
                        </Col>

                        <Col xs="auto">
                          <Form.Label
                            htmlFor="inlineFormInputGroup"
                            visuallyHidden
                          >
                            Choice
                            <font className="label-comment">*</font>
                          </Form.Label>
                          <select
                            name="choice"
                            className="selection-box  input-placeholder mb-2"
                            value={this.state.choice}
                            onChange={this.handleInputState}
                          >
                            <option key={11} value="">
                              {" "}
                              - Choice -{" "}
                            </option>
                            <option key={12} value="1">
                              First Choice
                            </option>
                            <option key={13} value="2">
                              Second Choice
                            </option>
                            <option key={14} value="3">
                              Third Choice
                            </option>
                            <option key={15} value="4">
                              Fourth Choice
                            </option>
                            <option key={16} value="5">
                              Fifth Choice
                            </option>
                          </select>
                          {this.state.errors.choice ? (
                            <div className="errorMsg">
                              {this.state.errors.choice}
                            </div>
                          ) : null}
                        </Col>

                        <Col xs="auto" style={{ paddingTop: "25px" }}>
                          <Button
                            type="submit"
                            className="btn btn-round  btn-xs mb-2"
                            variant="success"
                            style={{
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              fontSize: "12px",
                            }}
                            onClick={this.handleAlternate.bind(this)}
                          >
                            + Add
                          </Button>
                        </Col>
                      </>
                    ) : null}
                  </>
                )}
              </Row>
              <Row>
                {this.state.program_error === true ? (
                  <div className="errorMsg" style={{ paddingLeft: "20px" }}>
                    *The campus and program you added already exists, Your
                    choices should be:
                    <ul>
                      <li> 1. Same campus-different programmes</li>
                      <li> 2. Same program-different campuses</li>
                    </ul>
                  </div>
                ) : null}
                {this.state.choice_error === true ? (
                  <div className="errorMsg" style={{ paddingLeft: "20px" }}>
                    * You can't have more than one choice of the same
                  </div>
                ) : null}
                {this.state.not_filled_all === true ? (
                  <div className="errorMsg" style={{ paddingLeft: "20px" }}>
                    * Please select all (i.e Campus, program and choice)
                  </div>
                ) : null}
              </Row>
            </>
          ) : null}
          {this.state.submit_program.length > 0 &&
          this.state.submit_program.length < 6 ? (
            <>
              <Row>
                <hr />
              </Row>
              <Row>
                <Col xs="auto">
                  <div className="form-group">
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn btn-round  btn-md"
                    >
                      <FaRegSave />
                      {"     "} Submit Application Program (s)
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          ) : null}
        </form>
        <Row>
          <br></br>
          <br></br>
        </Row>
      </>
    );
  }
}

export default AddApplyingProgram;
