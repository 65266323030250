import React, { Component } from "react";
import { Row, Button } from "react-bootstrap";
import toTitleconverttoTitle from "../../util/toTitleConveter";
import { FaRegHandPointDown, FaArrowAltCircleRight } from "react-icons/fa";
import api from "../../util/api";
import params from "../../util/api_routes/params";
import ReactLoading from "react-loading";
import changeStage from "../../util/changeStage";
import { withRouter } from "react-router";
class ApplicantProfileComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      first_name: "",
      last_name: "",
      middle_name: "",
      sex: "",
      dob: "",
      citizenship: "",
      countryOfResident: "",
      region: "",
      district: "",
      cellPhone: "",
      email: "",
      expireDate: "",
      control_number: "",
      info_loading: null,
      accademic_year: "",
      award: "",
      isPaid: "",
      billAmount: "",
      currency: "",
      stage: parseInt(localStorage.getItem("stageid")),
      network_error: false,
      sex: "",
    };
  }

  componentDidMount() {
    this.getControlNumber();
  }

  componentWillReceiveProps(props) {
    this.setState({
      first_name: toTitleconverttoTitle(props.user_data.firstname),
      middle_name:
        props.user_data.middlename !== null
          ? toTitleconverttoTitle(props.user_data.middlename)
          : "",
      last_name:
        props.user_data.surname !== null
          ? toTitleconverttoTitle(props.user_data.surname)
          : "",
      dob: props.user_data.dob,
      cellPhone: props.user_data.cellPhone,
      citizenship: props.user_data.citizenship,
      countryOfResident: props.user_data.countryOfResident,
      region: props.user_data.region,
      district: props.user_data.district,
      email: props.user_data.applicationProfile.email,
      accademic_year: props.user_data.applicationProfile.academicYear,
      award: props.user_data.applicationProfile.award.award,
      sex: props.user_data.sex,
    });
  }

  nextToConfirmationSubmission = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 2,
    };
    let result = await changeStage(payLoad);
    if (result === 1) {
      this.setState({ loading: false, network_error: false });
      this.props.history.push("/personal/nextofkin");
    } else {
    }
  };

  refreshPage = async (e) => {
    e.preventDefault();
    window.location.reload();
  };

  getControlNumber = async () => {
    this.setState({ info_loading: true });
    let payLoad = {
      // applicationProfileId: 202100001,
      applicationProfileId: parseInt(localStorage.getItem("id")),
      platform: "oas",
    };

    // the following 3 lines should be commented after testing/video recording
    // this.setState({
    //   control_number: 994070432719,
    //   isPaid: 1,
    // });

    await api
      .post(params.CONTROL_NUMBER, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              control_number: res.data.data[0].controlNumber,
              expireDate: res.data.data[0].isExpired,
              isPaid: res.data.data[0].isPaid,
              billAmount: res.data.data[0].billAmount,
              currency: res.data.data[0].currency,
              info_loading: false,
              network_error: false,
            });
          }
        } else {
          this.setState({
            control_number: "",
            info_loading: false,
            network_error: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ network_error: true, info_loading: true });
      });
  };

  render() {
    // console.log(this.state.stage);
    return (
      <React.Fragment>
        <Row xl="12" sm="12" md="12">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  Personal Details
                </div>
                <div className="float-right card-header-title">
                  Application Number: {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-block">
                <div className="block">
                  {this.state.stage === 1 ? (
                    <div className="row">
                      <div className="col-sm-4">
                        <p>
                          <b>Instructions</b>
                        </p>
                        <hr />
                        {this.state.info_loading === true ? (
                          <div className="row">
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2">
                              {/* <ReactLoading type="cylon" color="#000" /> */}
                            </div>
                            <div className="col-sm-6"></div>
                          </div>
                        ) : null}

                        {this.state.error === true ? (
                          <>
                            <div className="row">
                              <div className="col-sm-1"></div>
                              <div className="col-sm-10">
                                <div className="alert alert-warning">
                                  <strong>Warning!</strong> You are facing a
                                  connection problem, please contact the system
                                  administrator OR try later
                                </div>
                              </div>
                              <div className="col-sm-1"></div>
                            </div>
                          </>
                        ) : (
                          <>
                            {this.state.isPaid === 0 ? (
                              <>
                                <ul>
                                  Please verify your personal details and
                                  conduct payment using the control number below{" "}
                                  <FaRegHandPointDown size={20} />
                                  <li
                                    style={{ paddingTop: "8px", color: "red" }}
                                  >
                                    Application Fee Control Number :{" "}
                                    {this.state.control_number}
                                  </li>
                                  <li
                                    style={{
                                      paddingTop: "8px",
                                      color: "black",
                                    }}
                                  >
                                    Amount to be paid : {this.state.billAmount}{" "}
                                    {this.state.currency}{" "}
                                    {/* Amount to be paid : 20,000 TSH */}
                                    {/* {parseInt(
                                      localStorage.getItem("awardId")
                                    ) == 4 ? (
                                      <>
                                        Amount to be paid :{" "}
                                        {this.state.billAmount}{" "}
                                        {this.state.currency}{" "} 50,000 /=
                                      </>
                                    ) : (
                                      <>
                                        Amount to be paid :{" "}
                                        {this.state.billAmount}{" "}
                                        {this.state.currency}   20,000 /=
                                      </>
                                    )} */}
                                  </li>
                                </ul>
                              </>
                            ) : (
                              <>
                                {this.state.isPaid === 1 ? (
                                  <>
                                    <ul>
                                      Please verify you personal Information and
                                      continue filling other details
                                      <li
                                        style={{
                                          paddingTop: "8px",
                                          color: "red",
                                        }}
                                      >
                                        To continue please click the button
                                        below <FaRegHandPointDown size={20} />
                                      </li>
                                    </ul>
                                    <Button
                                      type="submit"
                                      className="btn btn-round  btn-md"
                                      variant="primary"
                                      onClick={this.nextToConfirmationSubmission.bind(
                                        this
                                      )}
                                    >
                                      Continue to Next of Kin information{" "}
                                      <FaArrowAltCircleRight size={20} />
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <h4>Please make payment first</h4>
                                    <Button
                                      type="submit"
                                      className="btn btn-round  btn-md"
                                      variant="primary"
                                      onClick={this.refreshPage.bind(this)}
                                    >
                                      CLICK HERE TO GET CONTROL NUMBER{" "}
                                      {/* <FaArrowAltCircleRight size={20} /> */}
                                    </Button>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div className="col-sm-8">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  backgroundColor: "#f1f5f7",
                                  fontWeight: "bold",
                                  fontFamily: "Helvetica",
                                  fontSize: "13px",
                                }}
                                colSpan="5"
                              >
                                APPLICANT INFORMATION
                              </th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  backgroundColor: "#f1f5f7",
                                  fontWeight: "bold",
                                  fontFamily: "Helvetica",
                                  fontSize: "12px",
                                }}
                              >
                                FULL NAME
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#f1f5f7",
                                  fontWeight: "bold",
                                  fontFamily: "Helvetica",
                                  fontSize: "12px",
                                }}
                              >
                                SEX
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#f1f5f7",
                                  fontWeight: "bold",
                                  fontFamily: "Helvetica",
                                  fontSize: "13px",
                                }}
                              >
                                BIRTHDATE
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#f1f5f7",
                                  fontWeight: "bold",
                                  fontFamily: "Helvetica",
                                  fontSize: "13px",
                                }}
                              >
                                CITIZENSHIP
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {this.state.first_name +
                                  " " +
                                  this.state.middle_name +
                                  " " +
                                  this.state.last_name}
                              </td>
                              <td>
                                {this.state.sex === "M" ? "Male" : "Female"}
                              </td>
                              <td> {this.state.dob}</td>
                              <td> {this.state.citizenship}</td>
                            </tr>

                            <tr>
                              <th
                                colSpan={5}
                                style={{
                                  backgroundColor: "#f1f5f7",
                                  fontWeight: "bold",
                                  fontFamily: "Helvetica",
                                  fontSize: "13px",
                                }}
                              >
                                Application Level & Year
                              </th>
                            </tr>
                            <tr>
                              <th colSpan={3}>Level</th>
                              <th colSpan={2}>Academic Year</th>
                            </tr>
                            <tr>
                              <th colSpan={3}>{this.state.award}</th>
                              <th colSpan={2}>{this.state.accademic_year}</th>
                            </tr>
                          </tbody>
                        </table>
                        <br></br>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  backgroundColor: "#f1f5f7",
                                  fontWeight: "bold",
                                  fontFamily: "Helvetica",
                                  fontSize: "13px",
                                }}
                                colSpan="5"
                              >
                                APPLICANT ADDRESS
                              </th>
                            </tr>
                            <tr>
                              <th>Country</th>
                              <th>Region</th>
                              <th>District</th>
                              <th>Phone</th>
                              <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{this.state.countryOfResident}</td>
                              <td>{this.state.region}</td>
                              <td>{this.state.district}</td>
                              <td>{this.state.cellPhone}</td>
                              <td>{this.state.email}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <>
                      {this.state.stage > 1 ? (
                        <div className="row">
                          <div className="col-sm-1"></div>
                          <div className="col-sm-10">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      backgroundColor: "#f1f5f7",
                                      fontWeight: "bold",
                                      fontFamily: "Helvetica",
                                      fontSize: "13px",
                                    }}
                                    colSpan="5"
                                  >
                                    APPLICANT INFORMATION
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      backgroundColor: "#f1f5f7",
                                      fontWeight: "bold",
                                      fontFamily: "Helvetica",
                                      fontSize: "12px",
                                    }}
                                  >
                                    FULL NAME
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#f1f5f7",
                                      fontWeight: "bold",
                                      fontFamily: "Helvetica",
                                      fontSize: "12px",
                                    }}
                                  >
                                    SEX
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#f1f5f7",
                                      fontWeight: "bold",
                                      fontFamily: "Helvetica",
                                      fontSize: "13px",
                                    }}
                                  >
                                    BIRTHDATE
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#f1f5f7",
                                      fontWeight: "bold",
                                      fontFamily: "Helvetica",
                                      fontSize: "13px",
                                    }}
                                  >
                                    CITIZENSHIP
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {this.state.first_name +
                                      " " +
                                      this.state.middle_name +
                                      " " +
                                      this.state.last_name}
                                  </td>
                                  <td>
                                    {this.state.sex === "M" ? "Male" : "Female"}
                                  </td>
                                  <td> {this.state.dob}</td>
                                  <td> {this.state.citizenship}</td>
                                </tr>

                                <tr>
                                  <th
                                    colSpan={5}
                                    style={{
                                      backgroundColor: "#f1f5f7",
                                      fontWeight: "bold",
                                      fontFamily: "Helvetica",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Application Level & Year
                                  </th>
                                </tr>
                                <tr>
                                  <th colSpan={3}>Level</th>
                                  <th colSpan={2}>Academic Year</th>
                                </tr>
                                <tr>
                                  <th colSpan={3}>{this.state.award}</th>
                                  <th colSpan={2}>
                                    {this.state.accademic_year}
                                  </th>
                                </tr>
                              </tbody>
                            </table>

                            <br></br>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      backgroundColor: "#f1f5f7",
                                      fontWeight: "bold",
                                      fontFamily: "Helvetica",
                                      fontSize: "13px",
                                    }}
                                    colSpan="5"
                                  >
                                    APPLICANT ADDRESS
                                  </th>
                                </tr>
                                <tr>
                                  <th>Country</th>
                                  <th>Region</th>
                                  <th>District</th>
                                  <th>Phone</th>
                                  <th>Email</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{this.state.countryOfResident}</td>
                                  <td>{this.state.region}</td>
                                  <td>{this.state.district}</td>
                                  <td>{this.state.cellPhone}</td>
                                  <td>{this.state.email}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-1"></div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

const ApplicantProfile = withRouter(ApplicantProfileComp);
export default ApplicantProfile;
