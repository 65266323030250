export default function emailManipulation(email) {
  let manipulatedEmail = "";
  const name = email.split("@");
  if (name[0].includes(".") === true) {
    var secondSplit = name[0].split(".");
    let firstMiddle = Math.floor(secondSplit[0].length / 2);
    let secondMiddle = Math.floor(secondSplit[1].length / 2);
    manipulatedEmail =
      secondSplit[0].substring(0, firstMiddle + 2) +
      "..." +
      secondSplit[1].substring(0, secondMiddle + 2) +
      "" +
      "@" +
      name[1];
  } else {
    let middleCounter = Math.floor(name[0].length / 2);
    manipulatedEmail =
      name[0].substring(0, middleCounter + 1) +
      "" +
      name[0].slice(-2) +
      "..." +
      "@" +
      name[1];
  }
  return manipulatedEmail;
}
