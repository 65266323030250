import axios from "api/axios";

const GET_REFERENCE = "/api/permit-info";
const DELETE_REFERENCE = "/api/permit-info/delete/";
const VIEW = "/api/permit-info/preview";

const getReferenceDetails = async (params) => {
  return await axios.get(`${GET_REFERENCE}`, { params });
};

const deleteReferenceDetails = async (id) => {
  return await axios.delete(`${DELETE_REFERENCE}${id}`);
};

const viewReferenceDetails = async (params) => {
  return await axios.get(`${VIEW}`, { params });
};

export { getReferenceDetails, deleteReferenceDetails, viewReferenceDetails };
