import React, { Component } from "react";
import { FaRegHandPointRight } from "react-icons/fa";

class ConfirmInstruction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: parseInt(localStorage.getItem("stageid")),
      campus: "",
      program: "",
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      campus: props.campus,
      program: props.program,
    });
  }

  render() {
    return (
      <>
        <p>
          <b>Instructions</b>
        </p>
        <hr />

        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>1.</span>
            <span style={{ color: "green", fontWeight: "bold" }}>
              Congratulation
            </span>
            , You have been selected to DMI in the programme{" "}
            <span style={{ fontWeight: "bold" }}> "{this.props.program}"</span>{" "}
            in <span style={{ fontWeight: "bold" }}>"{this.props.campus}"</span>{" "}
            Campus,
            {""}
            <span style={{ fontWeight: "bold" }}>
              But you have multiple selection from various institutions.
            </span>
            <li>
              {" "}
              <span style={{ fontWeight: "bold" }}>2.</span> Please request
              confirmation code from TCU and confirm to DMI.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>3.</span> Once you receive
              the confirmation code in your mobile, click confirm button to
              submit it.
            </li>
          </li>
          <li>
            <FaRegHandPointRight size={25} />
          </li>
        </ul>
      </>
    );
  }
}
export default ConfirmInstruction;
