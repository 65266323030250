import api from "../../../util/api";
const GET_TOKEN_API = "/api/oauth/token";
// const USER_DETAILS = "/api/user";
const USER_DETAILS = "/api/users/me";

const getUserDetails = async (TOKEN) => {
  return await api.get(`${USER_DETAILS}?access_token=${TOKEN}`);
};

const authenticateUser = async (params) => {
  const CLIENT = process.env.REACT_APP_CLIENT;
  const PASSWORD = process.env.REACT_APP_CLIENT_PASSWORD;
  const GRANT_TYPE = process.env.REACT_APP_CLIENT_GRANT_TYPE;
  const basicAuth = "Basic " + btoa(CLIENT + ":" + PASSWORD);

  const payload = `username=${encodeURIComponent(
    params.username
  )}&password=${encodeURIComponent(params.password)}&grant_type=${GRANT_TYPE}`;

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
    Authorization: basicAuth,
  };

  return api.post(GET_TOKEN_API, payload, {
    headers,
  });
};

export { authenticateUser, getUserDetails };
