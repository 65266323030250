import React, { Component } from "react";
import { FaRegHandPointDown, FaRegHandPointRight } from "react-icons/fa";
class CollegeFillingInstruction extends Component {
  render() {
    return (
      <>
        {this.props.status === "right" ? (
          <>
            <p>
              <b>Instructions</b>
            </p>
            <hr />

            <ul>
              Please fill your college education through answering the
              attendance question. <FaRegHandPointRight size={25} />
              <br></br>
              <li>
                If you have not attended any college, please select No and
                confirm
              </li>
              <li style={{ paddingTop: "5px" }}>
                <b>Note</b>. The fields with * are mandatory
              </li>
            </ul>
          </>
        ) : (
          <>
            <p>
              <b>Instructions</b>
            </p>
            <hr />

            <ul>
              Please fill your college education through answering the
              attendance question below
              <li>
                If you have not attended any Advanced Secondary school,please
                select No and confirm
              </li>
              <li style={{ paddingTop: "5px" }}>
                <b>Note</b>. The fields with * are mandatory
              </li>
              <br></br>
              <li>
                The form can found below <FaRegHandPointDown size={25} />
              </li>
              <br></br>
            </ul>
          </>
        )}
      </>
    );
  }
}
export default CollegeFillingInstruction;
