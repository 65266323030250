import React from "react";
import { Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer" style={{ position: "fixed", bottom: "0" }}>
      <Row>
        <Col sm={6}>
          {new Date().getFullYear()} © Dar es Salaam Maritime Institute.
        </Col>
        <Col sm={6}>
          <div className="text-sm-right d-none d-sm-block"></div>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
